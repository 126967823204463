import * as BABYLON from 'babylonjs'

export enum UVColor {
    White = "white",
    Red = "red",
    Yellow = "yellow",
    BlueDark = "blue-dark",
    BlueLight = "blue-light"
}
export const uvColorMap: { [col: string]: BABYLON.Color3 } = {
    'white' : BABYLON.Color3.White(),
    'red' : new BABYLON.Color3(1, 0.306, 0),
    'yellow' : new BABYLON.Color3(0.875, 0.863, 0.212),
    'blue-dark' : new BABYLON.Color3(0, 0.137, 0.365),
    'blue-light' : new BABYLON.Color3(0.667, 0.867, 0.886)
}
export const uvColorArray = [UVColor.Red, UVColor.Yellow, UVColor.BlueDark, UVColor.BlueLight]

export class UVInteractiveEffect {

    protected fxMat: BABYLON.NodeMaterial
    protected fxLogoMat: BABYLON.NodeMaterial

    constructor(linesMaterial: BABYLON.NodeMaterial, logoMaterial: BABYLON.NodeMaterial) {
        this.fxMat = linesMaterial
        this.fxLogoMat = logoMaterial
        this.setToDefaults()
    }

    // ------------------------------------------

    setToDefaults() {
        // console.log('UVInteractiveEffect.setToDefaults()')
    }

    // ------------------------------------------

    update(dt: number) {
        // console.log('UVInteractiveEffect.update()')
    }

    // ------------------------------------------

    private static getLinesMatInputBlock(mat: BABYLON.NodeMaterial, name: string): BABYLON.InputBlock | null {
        const b = mat.getBlockByName(name)
        if (b == null || (b as BABYLON.InputBlock).value === undefined) { return null }
        return b as BABYLON.InputBlock
    }

    protected static setMatInput(mat: BABYLON.NodeMaterial, blockName: string, val: number) {
        const b = UVInteractiveEffect.getLinesMatInputBlock(mat, blockName)
        if (b == null) { return }
        b.value = val
    }

    protected static setMatInputRounded(mat: BABYLON.NodeMaterial, blockName: string, val: number) {
        const b = UVInteractiveEffect.getLinesMatInputBlock(mat, blockName)
        if (b == null) { return }
        b.value = Math.round(val)
    }

    protected static setMatInputColor(mat: BABYLON.NodeMaterial, blockName: string, val: BABYLON.Color4) {
        const b = UVInteractiveEffect.getLinesMatInputBlock(mat, blockName)
        if (b == null) { return }
        b.value = val
    }

}