
import { UVInteractiveEffect } from './UVInteractiveEffect'
import { WaveData } from '../data/sound-data'

export class UVInnerWaveEffect extends UVInteractiveEffect {
    
    setToDefaults() {
        super.setToDefaults()
        
        this.setInnerWaveFreq(0)
        this.setInnerWaveAmp(0)
        this.setInnerWaveSpeed(0)
    }

    setWaveData(dat: WaveData) {
        this.setInnerWaveFreq(dat.frequency)
        this.setInnerWaveAmp(dat.amplitude)
        this.setInnerWaveSpeed(dat.speed)
    }

    setInnerWaveFreq(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'freq', val)
    }
    setInnerWaveAmp(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'amp', val)
    }
    setInnerWaveSpeed(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'Speed', val)
    }
}