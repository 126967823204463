














import firebase from 'firebase/app'

import { Component, Vue } from 'vue-property-decorator'
import ProjectView from '../components/ProjectView.vue'
import { ScrollLock } from '../ScrollLock'

import isMobile from "is-mobile"

import { ProjectData, projectData } from '../data/proj-data'

@Component({
  components: { ProjectView }
})
export default class Projects extends Vue {
  private projects: Array<ProjectData> = projectData

  private isMobileDevice = isMobile()

  destroyed() {
    ScrollLock.setScrollLockActive(false)
  }

  projectViewClicked(index: number) {
    this.$emit('project-selected', index)

    // -----------------------------
    const analytics = firebase.analytics()
    if (!analytics) { return }
    const proj = this.projects[index]
    analytics.logEvent('project_clicked', { project: proj.title })
  }

  // =============================

}
