export interface ProjectData {
    imgSrc: string | null;
    imgSrcSmall: string | null;
    title: string;
    
    vimeoID?: string;
    youtubeID?: string;
    bgImgSrc169?: string; // BACKUP to this is imgSrc
    externalLink?: string;
    externalLinkCTA?: string;

    description: string;
    client: string;
}

export const projectDataEmpty: ProjectData = {
    imgSrc: null,
    imgSrcSmall: null,
    title: 'TITLE',
    description: 'DESCRIPTION',
    client: 'CLIENT'
}

export const projectData: Array<ProjectData> = [
    {
        title: 'Machine Learning Tools for Musicians',
        client: '<b>Client</b>: Google',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F01-ml_musicians-v2.jpg?alt=media&token=6a599f78-3dbf-4716-a288-4521b9eb3c62',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F01-ml_musicians-v2.jpg?alt=media&token=33ce7606-c849-40f9-bdb5-c015a9a031d7',
        youtubeID:'MbKsRJ5MsnA',
        description: 'UV collaborated with Google\'s Creative Lab in Sydney and musicians Briggs, Milan Ring and Cosmo\'s Midnight to explore possibilities for new, machine learning-powered tools which could assist artists in their creative process. Together we developed two experimental prototypes, one which helps artists create a demo by enabling a singer\'s voice to be transformed into other instruments; the other which uses machine learning to provide inspiration for lyrics.\
        <br><br>You can also check out our ARIA Masterclass \'AI and the Future of Music\' →\
        <br><a href="https://youtu.be/Ilaa3rp6HWU">https://youtu.be/Ilaa3rp6HWU</a>',
    },{
        title: '\'Music of the Sails\' - Sydney Opera House 50th Anniversary',
        client: '<b>The Sydney Opera House</b>',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F02-Music%20of%20the%20Sails_1200.jpeg?alt=media&token=6e5b081e-9efc-41c4-8fda-096fd16b99e2&_gl=1*17lig0q*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzA5Nzg4MS4zLjEuMTY5NzA5OTIzOS42MC4wLjA.',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F02-Music%20of%20the%20Sails_600.jpeg?alt=media&token=f18709d3-2893-46c3-8fee-f809d5811e4c&_gl=1*1n0pxb6*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzA5Nzg4MS4zLjEuMTY5NzA5OTEwMS42MC4wLjA.',
        // youtubeID: 'sAzULywAHUM',
        vimeoID: '873658880',
        description: 'Music of the Sails is an ambitious digital artwork that reimagines the sounds of the world-famous building in a unique audio-visual soundscape running for 744 hours across the entire month of October.\
        <br>A generative, audio-visual artwork made from data, artificial intelligence and field recordings, including the sounds of the Concert Hall\'s grand organ, Music of the Sails augments the sonic secrets of the building in a way that we\'ve never heard before.',
    },{
        title: 'MEMU - AI Powered Music Engine',
        client: 'Uncanny Valley',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F03-memu-v4-byline.jpeg?alt=media&token=c4f9fb74-e539-4a3b-863e-c9171e50ef0a',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F03-memu-v4-byline.jpeg?alt=media&token=dc141c3b-d792-4c57-a984-8a97876be21a',
        bgImgSrc169: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F03-memu-v4.jpeg?alt=media&token=93f49c72-7a80-4c18-a5a7-ce20aab1c0c2',
        externalLink: 'https://memu.live',
        externalLinkCTA: 'Listen live',
        description: 'MEMU is a new era in music production, powered by AI. It takes small catchy bites of music and turns them into songs and suggestions, just like a meme. MEMU\'s prolific production engine generates musical ideas for use in DJ mixes, songwriting, streaming, gaming and more.',
    },{
        title: 'Play On (LFC) - Standard Chartered Bank',
        client: '<b>Client</b>: Standard Charted Bank',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F04-LFC_Playon_Thumbnail_1200.jpeg?alt=media&token=2e2db8d4-2a84-47e5-943a-12f0d1ce06da&_gl=1*wjlvxf*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzA5Nzg4MS4zLjEuMTY5NzA5OTI4Ny4xMi4wLjA.',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F04-LFC_Playon_Thumbnail_600.jpeg?alt=media&token=3353ca4a-a77d-4d55-81e2-f8f4fefbab4d&_gl=1*3cec1c*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzA5Nzg4MS4zLjEuMTY5NzA5OTE1OS4yLjAuMA..',
        vimeoID: '872832331',
        description: 'Collaborating with director Armand De Saint Salvy, UV composed an original track for Standard Chartered Bank and Liverpool Football Club\'s \'Play On\' campaign in the lead up to the Women\'s World Cup. The campaign was designed to encourage girls to stay in sport in order to help them learn skills like teamwork, leadership and resilience, so they can follow their dreams both on and off the field.\
        <br><br>Agency: electriclime° and Octagon',
    },{
        title: 'Boys Do Cry',
        client: '<b>Client</b>: The Hallway',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2Fuv_bdc_1200-750.jpg?alt=media&token=9ecc7727-3d66-4cbb-8ded-283eabf8ae91',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2Fuv_bdc_600-375.jpg?alt=media&token=ce6eb22b-2238-4def-9fa2-341b2e0f441c',
        //bgImgSrc169: 'WE NEED A 16:9 IMAGE TO SIT WHERE THE VIDEO WOULD BE',
        youtubeID: 'PMBNYRiBNT8',
        description: '“Boys Do Cry” is a nationwide campaign challenging societal views that men should always put on a brave front. Partnering with The Hallway\'s ECD Simon Lee, Uncanny Valley helped transform The Cure\'s iconic song \'Boys Don\'t Cry\' into a strong message for men\'s mental health. Launched with a music video feat. Dallas Woods and directed by Tom Campbell (Good Oil), the campaign was only possible thanks to an amazing team: Robert Smith, Universal Music Publishing, University of Melbourne\'s Centre for Mental Health, Gotcha4Life, Heiress Films, UnLtd and media agency Initiative. The campaign has been recognised globally winning awards at D&AD London, Webbies NYC, Andys, OneShow, Spikes Asia, Award, MADC, Clios and Sirens.',
    },{
        title: 'The Day That You Moved On ft. Sia',
        client: '<b>Client</b>: TQX',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F06-tqx_sia.jpeg?alt=media&token=8225bd80-c647-41b8-a1c0-02dd5e244e8b',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F06-tqx_sia.jpeg?alt=media&token=8562edb4-fd4f-4acc-bdd0-54914e84c340',
        // vimeoID: '303955917',
        youtubeID: 'aRyPRIbirHk',
        description: '\'The Day That You Moved On\' featuring vocalist Sia is the first single from TQX\'s Global Intimacy, a political \'Black Mirror\' pop album. The track was produced by TQX (aka Barney McAll) and Uncanny Valley\'s Justin Shave and the album features artists such as Daniel Merriweather and Jagwar Ma\'s Gabriel Winterfield. The project was assisted by the Australian Government through the Australia Council.',
    },{
        title: 'ABC-Yours',
        client: '<b>Client</b>: ABC',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2Fabc_1200.jpeg?alt=media&token=cbdfc845-5b4b-4ff9-99f4-676c9190a9f1',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2Fabc_600.jpeg?alt=media&token=5590e2b2-6aca-46b3-84f5-f43ac9c7598a',
        // vimeoID: '284588488',
        youtubeID: 'xh9DusRmM-0',
        description: 'To help celebrate 90 years of television, we partnered with the Australian Broadcasting Corporation to bring together hundreds of amazing performers to create this majestic rendition of \'I Am Australian\' for a multi-film campaign.\
        <br>We also composed a show package for ABC 90 Celebrate!, a special live event to mark the celebration.\
        <br><br>Watch behind the scenes:\
        <br><a href="https://www.youtube.com/watch?v=TwJ0dQdvnws">https://www.youtube.com/watch?v=TwJ0dQdvnws</a>',
    },{
        title: 'The Voice: Power to the People',
        client: '<b>Client</b>: Seven Network',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F08_the-voice_1200.jpg?alt=media&token=a86856d4-984a-4d6f-bc30-6935041bfc82&_gl=1*lc04b8*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzE0ODYwMS40LjEuMTY5NzE0ODgzNy41MS4wLjA.',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F08_the-voice_600.jpg?alt=media&token=1134679c-b078-4aec-ace0-d149771e4c88&_gl=1*gapd40*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzE0ODYwMS40LjEuMTY5NzE0ODgxMS4zLjAuMA..',
        youtubeID: 'Ougmh_dc-QI',
        description: 'The world stops for The Voice. Global sensation and new Voice coach, Jason Derulo, joins returning superstar coaches Rita Ora, Guy Sebastian, and Jessica Mauboy.\
        <br>Vocalist Reigan Derry.',
    },{
        title: 'The Summit',
        client: 'Endemol Shine',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F09-The_Summit_Thubnail_1200.jpg?alt=media&token=56b3eb17-54b5-4df7-9773-88b263154b32&_gl=1*12bjg0m*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzA5Nzg4MS4zLjEuMTY5NzA5OTIwNi4zMC4wLjA.',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F09-The_Summit_Thubnail_600.jpg?alt=media&token=f57c4fb1-1dbf-4df4-87f5-0535b77e6952&_gl=1*mw258m*_ga*MTY1NTY2NTQwMC4xNjk0OTg5OTk2*_ga_CW55HF8NVT*MTY5NzA5Nzg4MS4zLjEuMTY5NzA5OTEzNy4yNC4wLjA.',
        vimeoID: '872839056',
        description: 'The Summit series theme and score: In the rugged Alps of New Zealand\'s southern island, a group of strangers will embark on an epic quest. They\'ll need to work as a team as they tackle dangerous terrain, wild conditions and overcome adrenaline-charged challenges to reach the peak of a distant mountain in just 14 days to win the cash they\'re carrying.',
    },{
        title: 'Ministry of Sound Classical',
        client: '<b>Client</b>: TMRW Music',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2Fuv_ministry_1200-750.jpg?alt=media&token=8977184d-7a53-409d-a305-d8d724e7565c',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2Fuv_ministry_600-375.jpg?alt=media&token=c0dd012a-e573-4988-b980-aa4eb55a4811',
        vimeoID: '717412088',
        description: 'Since 2018, UV has musically directed and produced Ministry of Sound\'s Orchestrated and Classical live tour, completely reproducing an ever increasing playlist of classic dance tracks from scratch. Scored for a 35 piece live orchestra, band and international vocalists, the novel arrangements add an epic dimension to the well-loved tracks for a spectacular live event.',
    },{
        title: 'Here',
        client: '<b>Client</b>: Fox Sports',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2F11-here_fox.jpg?alt=media&token=f0115442-a674-43a6-8a5a-b143e2b30d9e',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2F11-here_fox.jpg?alt=media&token=1882e002-1f16-4bf8-9152-c69578466af6',
        vimeoID: '219036079',
        description: 'Fox Sports approached UV to create the brand sound for their new, dedicated NRL channel, Fox League.It was a chance to deliver not only the sonic identity of a sporting code but reimagine the message of the entire sport in Australia. Collaborating with Briggs, Aussie hip hop artist and co-founder of indigenous act A.B. Original, we delivered a brass driven, lyrical anthem \'Here\' for a new generation of NRL. The track went on to be released as a single via Briggs\' hip hop label \'Bad Apples\', was performed at numerous live events including the NRL Indigenous Round and won the Australian Guild of Screen Composers Best TV Theme.',
    },{
        title: 'Uncanny Valley Talks',
        client: 'Uncanny Valley',

        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F1200x750%2Fuv_tedx_1200-750.jpg?alt=media&token=3e97ca4c-29f8-499e-8297-b70ccc638409',
        imgSrcSmall: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/projects%2F600x375%2Fuv_tedx_600-375.jpg?alt=media&token=54d2958a-7fba-47d9-805e-62e708fd1be8',
        youtubeID: 'UZqKThGdlA0',
        description: 'As a means of engaging and informing brands and audiences about the future of technology in music, UV founders Justin Shave and Charlton Hill speak at industry conferences around the globe, including: TEDx, The World Science Festival, Cannes Lions, Spikes Asia, Adfest, APRA, Promax and the Computer Law Society. They are available to speak at your conference on a range of music and audio technology and sonic branding topics. Talks can be tailored specifically to suit the needs of your conference.<br><br>To book, contact <a href="mailto:producer@uncannyvalley.com.au">producer@uncannyvalley.com.au</a>.',
    }
]