
































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { UVInteractiveController, UVInteractiveDelegate } from '../interactive-lines/UVInteractiveController'

import KeyboardIcon from '../assets/keyboard-o.svg'
import { soundData, UVSound, UVButtonPosition } from '../data/sound-data'
import { AudioPlayerState, AudioPlayerUIResponder, AudioPlayer } from '../AudioPlayer'

// import variables from '../styles/variables.scss'

enum MobileInteractionMode {
  Inactive0 = 0,
  TransitionToShimmer1 = 1,
  Shimmer2 = 2,
  // TRANSITION TO ACTIVE?
  Active3 = 3,
  TransitionFromActive4 = 4
}

@Component({ components: { KeyboardIcon } })
export default class UVInteractive extends Vue implements UVInteractiveDelegate, AudioPlayerUIResponder {
  
  private controller!: UVInteractiveController

  @Prop() readonly isMobile!: boolean
  @Prop() readonly heroIsVisible!: boolean
  @Watch('heroIsVisible', { immediate: false }) 
  onHeroIsVisibleChanged() {
    // console.log('heroIsVisible CHANGED')
    if (this.isMobile || !this.controller) { return }

    this.memuPlayer.performFade({ fadeIn: this.heroIsVisible })
  }

  // -----------------------------------
  // MOBILE
  private _mobileMode = MobileInteractionMode.Inactive0
  private get mobileMode() { return this._mobileMode }
  private set mobileMode(newVal: MobileInteractionMode) {
    if (this._mobileMode == newVal) { return }
    this._mobileMode = newVal
    
    // const str = this._mobileMode == MobileInteractionMode.Inactive0 ? '0- INACTIVE' : 
    //             this._mobileMode == MobileInteractionMode.TransitionToShimmer1 ? '1- TRANSITION TO SHIMMER' :
    //             this._mobileMode == MobileInteractionMode.Shimmer2 ? '2- SHIMMER' :
    //             this._mobileMode == MobileInteractionMode.Active3 ? '3- ACTIVE' : 
    //             this._mobileMode == MobileInteractionMode.TransitionFromActive4 ? '4- TRANSITION FROM ACTIVE' : '???'
    // console.log(str)

    this.cascadeMobBtnsActive = // SET cascadeMobBtnsActive
           newVal == MobileInteractionMode.TransitionToShimmer1 
        || newVal == MobileInteractionMode.Shimmer2

    this.controller.isLogoVisible = 
           this._mobileMode > MobileInteractionMode.TransitionToShimmer1
        && this._mobileMode < MobileInteractionMode.TransitionFromActive4

    if (this._mobileMode == MobileInteractionMode.Active3) {
      setTimeout(() => {
        this.enterSiteVisible = true
      }, 1300)
    } else if (this.enterSiteVisible) {
      this.enterSiteVisible = false
    }

    this.updateShowInteractionTooltip()
  }
  private cascadeMobBtnsActive = false
  private interactionsAreActive = false
  private enterSiteVisible = false

  private showInteractionTooltip = false
  updateShowInteractionTooltip() {
    
    this.showInteractionTooltip = // SET showInteractionTooltip
          !this.controller.loadingScreenVisible
          && !(window.scrollY > 0) 
          && this._mobileMode == MobileInteractionMode.Inactive0
    
    // console.log('updateShowInteractionTooltip', this.showInteractionTooltip)
  }

  private mobileButtonRows = 0
  private mobileButtonCols = 0
  private touchDownTimestamp = 0
  private lastBtnDownPos: UVButtonPosition | null = null

  // -----------------------------------
  // MEMU Stream
  private memuPlayer!: AudioPlayer
  private memuStreamPlayPauseState: AudioPlayerState = AudioPlayerState.Loading
  // -----------------------------------

  private boundWindowResizeFunc: (() => void) | null = null
  private boundScrollFunc: ((evt: Event) => void) | null = null
  // private boundWindowFocusFunc: ((evt: FocusEvent) => void) | null = null
  // private boundWindowBlurFunc: ((evt: FocusEvent) => void) | null = null

  created () {
    this._mobileMode = MobileInteractionMode.Inactive0
    this.memuStreamPlayPauseState = AudioPlayerState.Loading

    this.boundWindowResizeFunc = this.windowResized.bind(this)
    this.boundScrollFunc = this.handleScroll.bind(this)
    // this.boundWindowFocusFunc = this.windowFocusOccurred.bind(this)
    // this.boundWindowBlurFunc = this.windowBlurOccurred.bind(this)

    window.addEventListener('resize', this.boundWindowResizeFunc)
    window.addEventListener('scroll', this.boundScrollFunc)
    // window.addEventListener('focus', this.boundWindowFocusFunc)
    // window.addEventListener('blur', this.boundWindowBlurFunc)

    if (this.isMobile) { 
      this.initMobileButtonRects()
      window.addEventListener('touchstart', this.touchStart.bind(this))
      window.addEventListener('touchmove', this.touchMove.bind(this), { passive: false })
      window.addEventListener('touchcancel', this.touchCancel.bind(this))
      window.addEventListener('touchend', this.touchEnd.bind(this))
    }
  }
  
  mounted () {
    // console.log('UVInteractive mounted isMobile: ', this.isMobile)

    
    const renderCanvas = this.$refs.renderCanvas as HTMLCanvasElement
    this.controller = new UVInteractiveController(this, renderCanvas, this.isMobile)
    // this.hideRenderCanvasIfRequired()

    const audioEl = document.getElementById('audio-player') as HTMLAudioElement
    if (audioEl == null) { 
      console.error('AUDIO PLAYER NOT FOUND')
      return
    }

    this.memuPlayer = new AudioPlayer({
      audioElement: audioEl,
      uiResponder: this, 
      continueStreamingOnPause: !this.isMobile,
      maxVolume: 1.0
    })
    this.memuStreamPlayPauseState = this.memuPlayer.stateVisual
    this.memuPlayer.setVolume(1.0)
    // this.memuPlayer.debug = (process.env.NODE_ENV == 'development')

  }
  destroyed () {
    if (this.boundWindowResizeFunc) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
    // if (this.boundWindowFocusFunc) { window.removeEventListener('focus', this.boundWindowFocusFunc) }
    // if (this.boundWindowBlurFunc) { window.removeEventListener('blur', this.boundWindowBlurFunc) }

  }

  // eslint-disable-next-line
  windowResized() {
    // if (window.innerHeight == this.lastWindowHeight) { return }
    // console.log('Hero::windowResized, isMobile: ', this.isMobile)

  }

  // eslint-disable-next-line
  handleScroll(ev: Event) {
    // console.log('Hero::handleScroll')
    this.updateShowInteractionTooltip()

    // if (this.interactionsAreActive || this.cascadeMobBtnsActive) { return }
    // this.showInteractionTooltip = !(window.scrollY > 0)
  }
  
  // ====================================================

  //  #       #######    #    ######  ### #     #  #####      #####   #####  ######  ####### ####### #     # 
  //  #       #     #   # #   #     #  #  ##    # #     #    #     # #     # #     # #       #       ##    # 
  //  #       #     #  #   #  #     #  #  # #   # #          #       #       #     # #       #       # #   # 
  //  #       #     # #     # #     #  #  #  #  # #  ####     #####  #       ######  #####   #####   #  #  # 
  //  #       #     # ####### #     #  #  #   # # #     #          # #       #   #   #       #       #   # # 
  //  #       #     # #     # #     #  #  #    ## #     #    #     # #     # #    #  #       #       #    ## 
  //  ####### ####### #     # ######  ### #     #  #####      #####   #####  #     # ####### ####### #     # 
                                                                                                      

  loadingScreenWasHidden() {
    // console.log('loadingScreenWasHidden')

    setTimeout(() => {
      this.updateShowInteractionTooltip()
      // if (this.cascadeMobBtnsActive) { return }
      // this.showInteractionTooltip = true
    }, 1000)
  }

  // ===============================

  //  #     # ####### ######     ### #     # ######  #     # ####### 
  //  ##   ## #     # #     #     #  ##    # #     # #     #    #    
  //  # # # # #     # #     #     #  # #   # #     # #     #    #    
  //  #  #  # #     # ######      #  #  #  # ######  #     #    #    
  //  #     # #     # #     #     #  #   # # #       #     #    #    
  //  #     # #     # #     #     #  #    ## #       #     #    #    
  //  #     # ####### ######     ### #     # #        #####     #    
                                                                

  startInteractivityOnMobile(): boolean {
    if (window.scrollY > 0) { return false }

    this.mobileMode = MobileInteractionMode.TransitionToShimmer1
    // this.cascadeMobBtnsActive = true
    // this.showInteractionTooltip = false

    // Fade out ALL Web UI (in Hero.vue)
    this.$emit('interaction-mode-changed-state', true)

    setTimeout(() => {
      this.controller.isLogoVisible = true
    }, 900)

    setTimeout(() => {
      // ACTIVATE INTERACTION
      this.mobileMode = MobileInteractionMode.Shimmer2
    }, 1650)
    return true
  }

  touchOccurredAtScreenPosition(t: Touch) {
    const xPercent = t.clientX / window.innerWidth
    const yPercent = t.clientY / window.innerHeight

    const xIndex = Math.floor( this.mobileButtonRows * xPercent )
    const yIndex = Math.floor( this.mobileButtonCols * yPercent )

    // EXCEPTION: "Deactivate the two tap zones around ‘enter site’ 
    //             so you don’t accidentally enter the site while having a fun time"
    if (this._mobileMode == MobileInteractionMode.Active3 
        && yIndex == this.mobileButtonCols - 1 
        && xIndex > 0 
        && xIndex < this.mobileButtonRows - 1) { return }
    // - - - - - - - - - - - - - - - - - - - - -

    if (this._mobileMode != MobileInteractionMode.Active3) {
      this.mobileMode = MobileInteractionMode.Active3
    }

    if (this.lastBtnDownPos != null) {
      if (this.lastBtnDownPos.x == xIndex && this.lastBtnDownPos.y == yIndex) { return }
      this.lastBtnDownPos.x = xIndex
      this.lastBtnDownPos.y = yIndex
    } else {
      this.lastBtnDownPos = { x: xIndex, y: yIndex }
    }

    // =================================
    // Make square button flash on mobile (the colour or white)
    const key = 'mob-trigger-'+xIndex+','+yIndex
    const elArray = this.$refs[key] as Element[]
    if (elArray != null) {
      const btn =  elArray[0]
      if (btn.classList.contains('flashanim')) {
        btn.classList.remove('flashanim')
        setTimeout(() => { btn.classList.add('flashanim') })
      } else {
        btn.classList.add('flashanim')
      }
      
      setTimeout(() => { btn.classList.remove('flashanim') }, 550)
    }
    // =================================

    // console.log('xPercent', xPercent, 'yPercent', yPercent)
    // console.log('t.clientX', t.clientX, 't.clientY', t.clientY)
    // console.log('window.innerWidth', window.innerWidth, 'window.innerHeight', window.innerHeight)
    // console.log('xIndex', xIndex, 'yIndex', yIndex)

    this.controller.triggerSoundWithPosition({ x:xIndex, y:yIndex })
  }

  shouldIgnoreTouchEvent(evt: TouchEvent): boolean {
    if (this.controller.loadingScreenVisible || 
        this._mobileMode == MobileInteractionMode.TransitionToShimmer1
     || this._mobileMode == MobileInteractionMode.TransitionFromActive4) { 

         return true
    }

    if (evt.target == null) { return false }
    const target = evt.target as HTMLElement

    if (target.hasAttribute('id') 
        && (target.id == 'enter-site-btn' || target.id == 'down-btn')) {
      // console.log('TARGET', evt.target)
      return true
    }
    return false
  }

  touchStart(evt: TouchEvent) {
    if (this.shouldIgnoreTouchEvent(evt)) { return }

    this.touchDownTimestamp = evt.timeStamp

    if (!this.cascadeMobBtnsActive && !this.interactionsAreActive) { return }
    // ACCEPTING SOUND TRIGGERS
    for (let i = 0; i < evt.touches.length; i++) {
      const t = evt.touches[i];
      this.touchOccurredAtScreenPosition(t)  
    }

    // evt.preventDefault()
  }
  touchMove(evt: TouchEvent) {
    // console.log('touchMove', evt)
    if (this.shouldIgnoreTouchEvent(evt)) { return }

    if (this.touchDownTimestamp > 0) { this.touchDownTimestamp = 0 }

    if (!this.cascadeMobBtnsActive && !this.interactionsAreActive) { return }
    this.touchOccurredAtScreenPosition(evt.touches[0])

    evt.preventDefault()
  }
  
  // evt: TouchEvent
  touchCancel() {
    // console.log('touchCancel', evt)
    // const time = evt.timeStamp

    this.lastBtnDownPos = null
  }
  // evt: TouchEvent
  touchEnd() {
    // console.log('touchEnd', evt)
    this.lastBtnDownPos = null
    // if (this.touchDownTimestamp < 1) { return }

    // -------------------------------------
    // if (this.cascadeMobBtnsActive || this.interactionsAreActive) { return }
    // else if (!this.cascadeMobBtnsActive && !this.interactionsAreActive) {
      // ACCEPTING SOUND TRIGGERS
      // evt.preventDefault()
    // }

    // if (window.scrollY > 0) { return }

    // const deltaTime = evt.timeStamp - this.touchDownTimestamp
    // this.touchDownTimestamp = 0
    // if (deltaTime > 650) { return }
    // // console.log('DELTA TIME', deltaTime)

    // if (this.startInteractivityOnMobile()) {
    //   evt.preventDefault()
    // }
  }

  // ===============================

  //  #     # ####### ######     ######  ####### #     #  #####  
  //  ##   ## #     # #     #    #     #    #    ##    # #     # 
  //  # # # # #     # #     #    #     #    #    # #   # #       
  //  #  #  # #     # ######     ######     #    #  #  #  #####  
  //  #     # #     # #     #    #     #    #    #   # #       # 
  //  #     # #     # #     #    #     #    #    #    ## #     # 
  //  #     # ####### ######     ######     #    #     #  #####  

  initMobileButtonRects() {
    // console.log(soundData)
    const arr: boolean[][] = []
    for (let i = 0; i < soundData.length; i++) {
        const sDatum: UVSound = soundData[i]
        if (sDatum.mobileButtonPos.x < 0 || sDatum.mobileButtonPos.y < 0) { continue }

        while (sDatum.mobileButtonPos.y >= arr.length) { arr.push([]) }
        while (sDatum.mobileButtonPos.x >= arr[sDatum.mobileButtonPos.y].length) { arr[sDatum.mobileButtonPos.y].push(false) }
        arr[sDatum.mobileButtonPos.y][sDatum.mobileButtonPos.x] = true
    }

    const longestRow: number = arr.reduce((longest: number, curArr: boolean[]) => {
        return Math.max(longest, curArr.reduce((count: number, val: boolean) => count + (val ? 1 : 0), 0))
    }, 0)
    // console.log('longestRow', longestRow)
    this.mobileButtonRows = longestRow

    let numCols = 0
    for (let y = 0; y < arr.length; y++) {
        const rowLength = arr[y].reduce((count: number, val: boolean) => count + (val ? 1 : 0), 0)
        if (rowLength < longestRow) { continue }

        ++numCols
    }
    this.mobileButtonCols = numCols
    // console.log('this.mobileBtnTooltips', this.mobileBtnTooltips)
  }

  tapToPlayMobileClicked() {
    // console.log('tapToPlayMobileClicked')
    this.startInteractivityOnMobile()
  }

  enterSiteButtonClicked() {
    // console.log('enterSiteButtonClicked')
    this.controller.isActive = false

    // this.memuPlayer.pause()
    this.memuPlayer.performFade({ 
      fadeIn: false
    })
  }                                                                                     

  //  ### #     # ####### ####### ######     #     #####  ####### ### #     # #######    ######  ####### #       
  //   #  ##    #    #    #       #     #   # #   #     #    #     #  #     # #          #     # #       #       
  //   #  # #   #    #    #       #     #  #   #  #          #     #  #     # #          #     # #       #       
  //   #  #  #  #    #    #####   ######  #     # #          #     #  #     # #####      #     # #####   #       
  //   #  #   # #    #    #       #   #   ####### #          #     #   #   #  #          #     # #       #       
  //   #  #    ##    #    #       #    #  #     # #     #    #     #    # #   #          #     # #       #       
  //  ### #     #    #    ####### #     # #     #  #####     #    ###    #    #######    ######  ####### ####### 
                                                                                                            

  getIsMobile(): boolean { return this.isMobile }

  interactionActiveStateChanged(isActive: boolean) {
    this.interactionsAreActive = isActive
    this.cascadeMobBtnsActive = false

    if (!this.isMobile || isActive) { return }
    // ==================================================
    // BELOW IS WHEN INTERACTIVITY BECOMES ** INACTIVE **

    setTimeout(() => {
      this.mobileMode = MobileInteractionMode.TransitionFromActive4
      // this.controller.isLogoVisible = false
    }, 400)

    setTimeout(() => {
      this.mobileMode = MobileInteractionMode.Inactive0
      // Fade out ALL Web UI (in Hero.vue)
      this.showInteractionTooltip = true
      this.$emit('interaction-mode-changed-state', false)
    }, 1200)
  }

  interactiveViewIsVisible(): boolean {
    return this.heroIsVisible
  }

  interactionAssetsFinishedLoading() {
    this.$emit('interaction-assets-finished-loading')
  }

  interactionSoundWasTriggered() {
    // Start playing Stream if it isn't playing already
    
    if (this.memuPlayer.isPlaying 
        || !this.isMobile 
        && (this.memuPlayer.state == AudioPlayerState.Play || this.memuPlayer.playbackHasOccured)) { 
      
      return
    } else {
      this.memuPlayer.play()
    }
  }

  // ==============================
  //  ######  #          #    #     #          #    ######     #    #     #  #####  ####### 
  //  #     # #         # #    #   #          #     #     #   # #   #     # #     # #       
  //  #     # #        #   #    # #          #      #     #  #   #  #     # #       #       
  //  ######  #       #     #    #          #       ######  #     # #     #  #####  #####   
  //  #       #       #######    #         #        #       ####### #     #       # #       
  //  #       #       #     #    #        #         #       #     # #     # #     # #       
  //  #       ####### #     #    #       #          #       #     #  #####   #####  ####### 


  playPauseBtnClicked() {
    // console.log('playPauseBtnClicked')
    // const stateStr = AudioPlayer.stringFromPlayerState(this.memuPlayer.state)
    // const uiStateStr = AudioPlayer.stringFromPlayerState(this.memuPlayer.stateVisual)
    // console.log('audioPlayerStateChanged:', stateStr, ', UI State:', uiStateStr)

    if (this.memuPlayer.state == AudioPlayerState.Loading) { return }

    if (this.memuPlayer.state == AudioPlayerState.Pause) { // PLAY
      this.memuPlayer.play()
    } else if (this.memuPlayer.state == AudioPlayerState.Play) { // PAUSE
      this.memuPlayer.pause()
    }
    // console.log('this.botLeftBtnMode', this.botLeftBtnMode)
  }

  // ==============================
  //     #    #     # ######  ### #######    ######  #          #    #     # ####### ######  
  //    # #   #     # #     #  #  #     #    #     # #         # #    #   #  #       #     # 
  //   #   #  #     # #     #  #  #     #    #     # #        #   #    # #   #       #     # 
  //  #     # #     # #     #  #  #     #    ######  #       #     #    #    #####   ######  
  //  ####### #     # #     #  #  #     #    #       #       #######    #    #       #   #   
  //  #     # #     # #     #  #  #     #    #       #       #     #    #    #       #    #  
  //  #     #  #####  ######  ### #######    #       ####### #     #    #    ####### #     # 

  audioPlayerStateChanged(state: AudioPlayerState, uiState: AudioPlayerState) {
    // const stateStr = AudioPlayer.stringFromPlayerState(state)
    // const uiStateStr = AudioPlayer.stringFromPlayerState(state)
    // console.log('audioPlayerStateChanged:', stateStr, ', UI State:', uiStateStr)

    this.memuStreamPlayPauseState = uiState
  }
  audioPlayerHadAnError(errStr: string) {
    console.log('audioPlayerHadAnError', errStr)

  }

}

