







import { Component, Vue } from 'vue-property-decorator'
import { tabDataDesktopArray } from '../data/tab-data'

@Component
export default class About extends Vue {
  private aboutDescHTML = 
    (tabDataDesktopArray[1].descriptionAlt ?? '')
    // .replace('about-content', 'about-content-hide')
    .replace('about-services mobile-only', 'about-services')
    // .replace('<br><b>Services</b><br>', '')

  // private desktopImageSrc = projectData[0].imgSrc

}

