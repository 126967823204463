




























import { Component, Vue, Prop } from 'vue-property-decorator'
import UVLogo from './UVLogo.vue'
import SocialsRow from './SocialsRow.vue'
import DownArrowSVG from '../assets/down-arrow.svg'
import UVInteractive from './UVInteractive.vue'

// import variables from '../styles/variables.scss'

@Component({
  components: { UVLogo, SocialsRow, DownArrowSVG, UVInteractive }
})
export default class Hero extends Vue {
  @Prop() readonly heroIsVisible!: boolean
  @Prop() readonly deviceIsMobile!: boolean

  static logoMinHeight = 92
  static maxPaddingTop = 32
  
  private interactionIsActive = false

  private socialsHidden = false
  private logoHidden = false
  private arrowOpacity = 1

  // -----------------------
  private returnToMobileActive = false
  // -----------------------

  private targetYTranslate = 0
  private targetScale = 1

  private logoY = 0
  private logoScale = 1

  // -----------------------
  static framerate = 60
  static framerateFraction = Hero.framerate * 0.001
  lastUpdateTime?: number

  // private updateInterval: number | null = null
  private lastUpdate: number = Date.now()
  private shouldUpdate = true
  private lastWindowHeight = 0
  private lastScrollY = 0
  private logoLerpActive = true
  
  // -----------------------

  // ---------------

  // https://codepen.io/ma77os/pen/KGIEh
  lerp (start: number, end: number, amt: number){
      return (1-amt)*start+amt*end
  }

  // UPDATE LOOP
  updateCallback(timestamp: number) {
    if (this.shouldUpdate) { requestAnimationFrame(this.updateCallback.bind(this)) }
    
    const dt = (this.lastUpdateTime === undefined) ? Hero.framerateFraction : timestamp - this.lastUpdateTime

    // const now = Date.now()
    // const timeDelta = now - this.lastUpdate;
    // this.lastUpdate = now
    // const delta = timeDelta * Hero.framerateFraction
    // - - - - - - - - - -
    // if (timeDelta > 1000) { return }
    // - - - - - - - - - -
    if (!this.logoLerpActive || 
        Math.abs(this.logoScale - this.targetScale) < 0.0001) { return }

    const amount = 8 * dt

    const newYTrans = this.lerp (this.logoY, this.targetYTranslate, amount)
    const newScale = this.lerp (this.logoScale, this.targetScale, amount)

    this.logoY = Math.round(newYTrans)
    this.logoScale = newScale

    // this.logoY = Math.round(this.targetYTranslate)
    // this.logoScale = this.targetScale
    // console.log('update')
  }

  // ---------------
  startUpdateLoop() {
    this.shouldUpdate = true
    // if (this.updateInterval != null) { return }
    // console.log('startUpdateLoop')
    requestAnimationFrame(this.updateCallback.bind(this))
    // this.updateInterval = setInterval (this.updateCallback.bind(this),
    //                     1000 / Hero.framerate)
  }
  stopUpdateLoop() {
    this.shouldUpdate = false
  }

  // // eslint-disable-next-line
  // windowBlurOccurred(_ev: FocusEvent) {
  //     this.stopUpdateLoop()
  // }
  
  // // eslint-disable-next-line
  // windowFocusOccurred(_ev: FocusEvent) {
  //     this.stopUpdateLoop()
  //     this.startUpdateLoop()
  // }
  // ---------------

  private boundWindowResizeFunc: (() => void) | null = null
  private boundScrollFunc: ((evt: Event) => void) | null = null
  // private boundWindowFocusFunc: ((evt: FocusEvent) => void) | null = null
  // private boundWindowBlurFunc: ((evt: FocusEvent) => void) | null = null

  created () {
    this.lastWindowHeight = window.innerHeight

    this.boundWindowResizeFunc = this.windowResized.bind(this)
    this.boundScrollFunc = this.handleScroll.bind(this)
    // this.boundWindowFocusFunc = this.windowFocusOccurred.bind(this)
    // this.boundWindowBlurFunc = this.windowBlurOccurred.bind(this)

    window.addEventListener('resize', this.boundWindowResizeFunc)
    window.addEventListener('scroll', this.boundScrollFunc)
    // window.addEventListener('focus', this.boundWindowFocusFunc)
    // window.addEventListener('blur', this.boundWindowBlurFunc)
  }
  mounted () {
    // console.log('mounted')
    // console.log('window.innerHeight: ', window.innerHeight)
    this.layoutLogo({ animated: false })
    
    setTimeout(() => {
      this.layoutLogo({ animated: true })
      this.startUpdateLoop()
    }, 300)
  }
  destroyed () {
    if (this.boundWindowResizeFunc) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
    if (this.boundScrollFunc) { window.removeEventListener('scroll', this.boundScrollFunc) }
    // if (this.boundWindowFocusFunc) { window.removeEventListener('focus', this.boundWindowFocusFunc) }
    // if (this.boundWindowBlurFunc) { window.removeEventListener('blur', this.boundWindowBlurFunc) }

    this.stopUpdateLoop()
  }

  layoutLogo(dat: { animated: boolean } = { animated: true }) {
    if (this.interactionIsActive) { return }

    this.logoLerpActive = dat.animated
    //this.targetYTranslate

    const logoVue = this.$refs.logoHero as Vue
    if (logoVue == null) { return }
    const logoEl = logoVue.$el as HTMLElement

    // console.log('window.innerHeight: ', window.innerHeight)

    const threshold = window.innerHeight - (Hero.logoMinHeight * 1.25)
    const topPixels = Math.min(threshold, window.scrollY)
    const percentToPage2 = topPixels / (window.innerHeight)
    const halfWindowHeight = window.innerHeight * 0.5

    this.arrowOpacity = Math.max(0, (1-percentToPage2 * 2))
    const newYVal = (halfWindowHeight - logoEl.clientHeight * 0.5)- (halfWindowHeight * percentToPage2)
    
    if (!dat.animated) { this.logoY = newYVal }
    this.targetYTranslate = newYVal
    // --------------------------------------

    const heightRange = logoEl.clientHeight - Hero.logoMinHeight
    const targetHeight = (Hero.logoMinHeight * 0.5) + (heightRange * (1-percentToPage2))
    const scale = targetHeight / logoEl.clientHeight

    // console.log('heightRange: ', heightRange)
    // console.log('targetHeight: ', targetHeight)
    // console.log('scale: ', scale)

    if (!dat.animated) { this.logoScale = scale }
    this.targetScale = scale
  }

  private resizeTimeout = 0

  // eslint-disable-next-line
  windowResized() {
    // if (window.innerHeight == this.lastWindowHeight) { return }
    // console.log('Hero::windowResized, deviceIsMobile: ', this.deviceIsMobile)

    this.layoutLogo({ animated: this.deviceIsMobile })

    // - - - - - - - - - -
    // Desktop breakpoint is width >= 992
    if (window.innerWidth >= 992 && this.shouldUpdate) {
      this.stopUpdateLoop()
    } else if (window.innerWidth < 992 && this.shouldUpdate == false) {
      this.returnToMobileActive = !this.deviceIsMobile

      this.startUpdateLoop()

      if (this.deviceIsMobile) { return }
      setTimeout(() => {
        this.returnToMobileActive = false
      }, 300)
    }
    // - - - - - - - - - -
    this.lastWindowHeight = window.innerHeight
    if (this.deviceIsMobile) { return }

    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.windowResizeDebounced.bind(this), 900)
  }
  windowResizeDebounced() {
    // console.log('Hero::windowResizeDebounced')
    
    this.layoutLogo({ animated: false })
  }

  // eslint-disable-next-line
  handleScroll(ev: Event) {
    if (window.scrollY == this.lastScrollY) { return }
    // console.log('Hero::handleScroll')

    this.layoutLogo({ animated: this.deviceIsMobile || !this.returnToMobileActive })
    
    this.lastScrollY = window.window.scrollY
  }

  downButtonClicked() {
    // console.log('downButtonClicked')
    this.$emit('scroll-down-clicked')
  }

// ==============================================================================

//  ### #     # ####### ####### ######     #     #####  ####### ### #     # ####### 
//   #  ##    #    #    #       #     #   # #   #     #    #     #  #     # #       
//   #  # #   #    #    #       #     #  #   #  #          #     #  #     # #       
//   #  #  #  #    #    #####   ######  #     # #          #     #  #     # #####   
//   #  #   # #    #    #       #   #   ####### #          #     #   #   #  #       
//   #  #    ##    #    #       #    #  #     # #     #    #     #    # #   #       
//  ### #     #    #    ####### #     # #     #  #####     #    ###    #    ####### 
                                                                                 

  interactionAssetsFinishedLoading() {
    this.$emit('interaction-assets-finished-loading')
  }

  interactionModeChangedState(interactionIsActive: boolean) {
    // console.log('interactionIsActive', interactionIsActive)
    this.interactionIsActive = interactionIsActive
    if (!this.deviceIsMobile) { return }

    const uiOpacity = interactionIsActive ? 0 : 1
    this.arrowOpacity = uiOpacity
    this.socialsHidden = interactionIsActive
    this.logoHidden = interactionIsActive

    this.$emit('interaction-mode-changed-state', interactionIsActive)
  }

}
