import * as BABYLON from 'babylonjs'

export interface UVInteractiveLoadingScreenDelegate {
    loadingScreenWasHidden(): void;
}

export class UVInteractiveLoadingScreen implements BABYLON.ILoadingScreen {
    //optional, but needed due to interface definitions
    loadingUIBackgroundColor = "#f00"
    loadingUIText = ""
 
    private _delegate: UVInteractiveLoadingScreenDelegate

    private _loadingDiv: HTMLDivElement | null = null

    private internalHidden = false
    private uvHidden = false

    static HIDDEN_STYLE = 'hidden'

    constructor(del: UVInteractiveLoadingScreenDelegate) {
        this._delegate = del
    }

    hiddenTogglesChanged() {
        // console.log('hiddenTogglesChanged')
        if (!this.internalHidden || !this.uvHidden) { return }

        // console.log('HIDE LOADING')
        if (this._loadingDiv == null 
         || this._loadingDiv.classList.contains(UVInteractiveLoadingScreen.HIDDEN_STYLE)) { return }

        this._loadingDiv.classList.add(UVInteractiveLoadingScreen.HIDDEN_STYLE)
        this._delegate.loadingScreenWasHidden()
    }

    hideLoadingScreen() {
        this.uvHidden = true
        this.hiddenTogglesChanged()
    }

    getLoaderHTML() {
        if (this._loadingDiv != null) { return }
        this._loadingDiv = document.getElementById('customLoadingScreenDiv') as HTMLDivElement
    }

    // windowResized() {

    // }

    // =================================================
    // * Function called to display the loading screen
    displayLoadingUI() {
        // console.log('DISPLAY LOADING')
        if (this._loadingDiv == null) { this.getLoaderHTML() }
        if (this._loadingDiv == null) { return }

        this._loadingDiv.classList.remove('hidden')
    }
  
    // * Function called to hide the loading screen
    hideLoadingUI() {
        this.internalHidden = true
        this.hiddenTogglesChanged()
    }
    // =================================================
  }