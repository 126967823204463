









































































import firebase from 'firebase/app'

import { Component, Prop, Vue } from 'vue-property-decorator'
import CloseIconSVG from '../assets/close-icon.svg'

import variables from '../styles/variables.scss'
import { ProjectData } from '../data/proj-data'

@Component({ components: { CloseIconSVG } })
export default class ProjectExpanded extends Vue {
  @Prop() private datum!: ProjectData

  private coverImageIsLoaded = false

  private videoHeight = '55%'
  private externalLinkWidth = '100%'

  private get showVimeo(): boolean { return this.datum.vimeoID != null }
  private get showYouTube(): boolean { 
    return this.datum.youtubeID != null && this.datum.vimeoID == null
  }
  private get showCoverImage(): boolean { 
    return this.datum.youtubeID == null && this.datum.vimeoID == null
  }
  private get showExternalLinkButton(): boolean { 
    return this.showCoverImage && this.datum.externalLink != null
  }

  private get getVimeoURL(): string {
    if (this.datum.vimeoID == null) { return '' }
    return 'https://player.vimeo.com/video/'+ this.datum.vimeoID +'?title=0&byline=0&portrait=0'
  }
  private get getYouTubeURL(): string {
    if (this.datum.youtubeID == null) { return '' }
    return 'https://www.youtube-nocookie.com/embed/' + this.datum.youtubeID //+ '?controls=0'
  }
  private get getCoverImgURL(): string {
    if (this.datum.bgImgSrc169 != null) { return this.datum.bgImgSrc169 }
    return this.datum.imgSrc ?? ''
  }
  private get getExternalLinkCTA(): string {
    return this.datum.externalLinkCTA ?? 'Visit project link'
  }

  private topAndBotRowXPadding = 0
  private boundWindowResizeFunc: (() => void) | null = null
  private boundKeyUpFunc: ((evt: KeyboardEvent) => void) | null = null

  created() {
    // console.log("ProjectExpanded::created")
    
    this.boundWindowResizeFunc = this.windowResized.bind(this)
    this.boundKeyUpFunc = this.keyUpEventOccured.bind(this)
    window.addEventListener('resize', this.boundWindowResizeFunc)
    document.addEventListener('keyup', this.boundKeyUpFunc)
  }
  mounted() {
    this.updateVideoHeight()
  }
  destroyed () {
    // console.log("ProjectExpanded::destroyed")
    if (this.boundWindowResizeFunc != null) { window.removeEventListener('resize', this.boundWindowResizeFunc) }
    if (this.boundKeyUpFunc != null) { document.removeEventListener('keyup', this.boundKeyUpFunc) }
  }

  keyUpEventOccured(evt: KeyboardEvent) {
    // console.log('keyUpEventOccured', evt)
    if (evt.code === "Escape" || evt.key === "Escape" || evt.key === "Esc") {
      this.closeBTNClicked()

      // -----------------------------
      const analytics = firebase.analytics()
      if (!analytics) { return }
      analytics.logEvent('project_expanded_closed', { method: 'Esc Key' })
    }
  }

  closeBTNClicked() {
    // console.log('closeBTNClicked')
    this.$emit('close-btn-clicked', variables.projExpandedAnimDuration)

    // -----------------------------
    const analytics = firebase.analytics()
    if (!analytics) { return }
    analytics.logEvent('project_expanded_closed', { method: 'Close Button' })
  }

  updateVideoHeight() {
    // console.log("updateVideoHeight", this.$refs)
    // 9/16 = 0.5625
    const wrapper = this.$refs.projExpandedView as HTMLElement
    let videoElement = this.$refs.vimeoFrame as HTMLElement
    if (!videoElement) { videoElement = this.$refs.youtubeFrame as HTMLElement }
    if (!videoElement) { 
      videoElement = this.$refs.coverImage as HTMLElement
      if (videoElement) { this.externalLinkWidth = videoElement.clientWidth + 'px' }
    }

    this.videoHeight = (videoElement.clientWidth * 0.47) + 'px'

    const totalXMargin = wrapper.clientWidth - videoElement.clientWidth
    this.topAndBotRowXPadding = Math.round(totalXMargin * 0.5)
  }

  windowResized() {
    this.updateVideoHeight()
  }

  coverImageLoaded() {
    // console.log('image loaded', ev)
    setTimeout(() => {
      this.coverImageIsLoaded = true
    }, 200)
  }

}
