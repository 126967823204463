import Vue from 'vue'
// import VueRouter, { RouteConfig, Route } from 'vue-router'
import VueRouter, { RouteConfig } from 'vue-router'
import About from '@/views/About.vue'
import Projects from '@/views/Projects.vue'
import Team from '@/views/Team.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Projects
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '*',
    redirect: '/'
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  // scrollBehavior (to: Route, from: Route, savedPosition: void | { x: number; y: number }) {
  //   // return desired position
  //   // console.log('scrollBehavior', to, from, savedPosition)
  //   if (to.hash) {
  //     return {
  //       selector: to.hash,
  //       behavior: 'smooth',
  //     }
  //   }
  // }
})

export default router
