

export enum AudioPlayerState {
    Play = 0,
    Loading = 1,
    Pause = 2,
    Error = 3
}

export interface AudioPlayerUIResponder {
    audioPlayerStateChanged(state: AudioPlayerState, uiState: AudioPlayerState): void;
    audioPlayerHadAnError(err: string): void;
}

interface FadeData {
    promise: Promise<void>;
    cancel: () => void;
}

// TODO: Add a visualiser
// https://codepen.io/heonie/pen/dBLYOP

export class AudioPlayer {

    public debug = false
    private _playbackHasOccured = false
    public get playbackHasOccured() { return this._playbackHasOccured }

    private domElem: HTMLAudioElement
    public uiResponder: AudioPlayerUIResponder

    private _state = AudioPlayerState.Loading
    public get state(): AudioPlayerState { return this._state }
    public get stateVisual(): AudioPlayerState {
        switch (this._state) { // If playing, show the PAUSE visual, etc...
            case AudioPlayerState.Play: return AudioPlayerState.Pause
            case AudioPlayerState.Pause: return AudioPlayerState.Play
            default: return this._state
        }
    }

    private continueStreamingOnPause: boolean

    private currentFade: FadeData | null = null
    private isFadedOut = false
    private maxVolume: number

    constructor(opts: { 
        audioElement: HTMLAudioElement; 
        uiResponder: AudioPlayerUIResponder;
        continueStreamingOnPause?: boolean; 
        maxVolume?: number;
    }) {

        this.domElem = opts.audioElement
        this.uiResponder = opts.uiResponder
        this.continueStreamingOnPause = opts.continueStreamingOnPause ?? false
        this.maxVolume = opts.maxVolume ?? 1.0

        opts.audioElement.autoplay = false
    
        opts.audioElement.oncanplay = this.onCanPlay.bind(this)
        opts.audioElement.oncanplaythrough = this.onCanPlayThrough.bind(this)
        opts.audioElement.onplay = this.onPlay.bind(this)
        opts.audioElement.onplaying = this.onPlaying.bind(this)
        opts.audioElement.onwaiting = this.onWaiting.bind(this)
        opts.audioElement.onpause = this.onPause.bind(this)
        opts.audioElement.onsuspend = this.onSuspend.bind(this)
        opts.audioElement.onstalled = this.onStalled.bind(this)
        opts.audioElement.onended = this.onEnded.bind(this)

        opts.audioElement.childNodes.forEach((source: ChildNode) => {
            const srcElem = source as HTMLSourceElement
            srcElem.onerror = this.onError.bind(this)
        })
    }

    // =======================================================================

    //  ######  #     # ######  #       ###  #####  
    //  #     # #     # #     # #        #  #     # 
    //  #     # #     # #     # #        #  #       
    //  ######  #     # ######  #        #  #       
    //  #       #     # #     # #        #  #       
    //  #       #     # #     # #        #  #     # 
    //  #        #####  ######  ####### ###  #####  

    get isPlaying() { 
        return !this.domElem.paused && !this.domElem.muted && this.domElem.volume > 0
    }
    play() {
        if (this.debug) { console.log('AudioPlayer::play()') }

        if (this.domElem.paused) { // IS PAUSED
            // console.log('START PLAYING')
            this.domElem.volume = 0 // Fade in will occur when playback starts
            this.domElem.play()
        } else if (this._playbackHasOccured) {
            if (this.domElem.muted) {
                this.domElem.volume = 0
                this.domElem.muted = false
            }
            // console.log('FADE IN')
            this.fade(0.6, this.maxVolume, false)
            
            this._state = AudioPlayerState.Play
            this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
        }
    }
    pause() {
        if (this.debug) { console.log('AudioPlayer::pause()') }
        if (this.continueStreamingOnPause) {
            this.domElem.muted = true
            
            this._state = AudioPlayerState.Pause
            this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
            
        } else {
            this.domElem.pause()
        }
    }
    setVolume(vol: number) {
        this.domElem.volume = vol
    }

    performFade(opts: { 
        fadeIn: boolean;
        toVolume?: number;
    }) {
        if (this.debug) { console.log('AudioPlayer::performFade()') }
        if (opts.fadeIn) {
            this.fadeIn(opts.toVolume ?? this.maxVolume)
        } else {
            this.fadeOut()
        }
    }

    private fadeVolumeASync(destVolume: number, duration: number): FadeData {
        // console.log('fadeVolumeASync')
        const durationMS = duration * 1000
        let finished = false
        const cancel = () => finished = true

        const promise = new Promise<void>((resolve) => {
            const startTime = Date.now()
            const endTime = startTime + durationMS
            const range = endTime - startTime

            const startVolume = this.domElem.volume
            
            const timer = setInterval(() => {
                const curTime = Date.now()
                const percent = Math.max(0, Math.min(1,(curTime - startTime) / range * duration))
                const invPercent = 1 - percent
                // console.log('percent', percent)
                this.domElem.volume = (startVolume * invPercent) + (destVolume * percent)
                
                if (finished || percent >= 1) {
                    clearInterval(timer)
                    resolve()
                }
            }, 10)
        })

        return { promise: promise, cancel: cancel }
    }

    private fade(duration: number, toVolume: number, isFadeOut: boolean) {
        if (this.currentFade != null) {
            this.currentFade.cancel()
        }
        this.isFadedOut = isFadeOut

        this.currentFade = this.fadeVolumeASync(toVolume, duration)
        this.currentFade.promise.then(() => {
            // console.log('FADE COMPLETE OR CANCELED')
            this.currentFade = null
        })
    }

    private fadeOut() {
        // TODO: Implement Fade and pause
        if (this.debug) { console.log('<AudioPlayer>::fadeOut') }

        if (this.continueStreamingOnPause) {
            this.fade(0.6, 0, true)
        } else {
            this.pause()
        }
    }
    private fadeIn(toVolume: number) {
        if (this.debug) { console.log('<AudioPlayer>::fadeIn') }

        if (this.continueStreamingOnPause) {
            this.fade(0.6, toVolume, false)
        } else {
            this.play()
        }
    }

    // =======================================================================

    //   #####     #    #       #       ######     #     #####  #    #  #####  
    //  #     #   # #   #       #       #     #   # #   #     # #   #  #     # 
    //  #        #   #  #       #       #     #  #   #  #       #  #   #       
    //  #       #     # #       #       ######  #     # #       ###     #####  
    //  #       ####### #       #       #     # ####### #       #  #         # 
    //  #     # #     # #       #       #     # #     # #     # #   #  #     # 
    //   #####  #     # ####### ####### ######  #     #  #####  #    #  #####  
                                                                        

    private onCanPlay() {
        if (this.debug) { console.log('<AudioPlayer>::onCanPlay') }

        if (this._state != AudioPlayerState.Loading) { return }
        this._state = AudioPlayerState.Pause
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
    }
    private onCanPlayThrough() {
        if (this.debug) { console.log('<AudioPlayer>::onCanPlayThrough') }
    }
    private onPlay() {
        if (this.debug) { console.log('<AudioPlayer>::onPlay') }
    }
    private onPlaying() {
        if (this.debug) { console.log('<AudioPlayer>::onPlaying') }
        
        if (!this.isFadedOut) {
            this.fadeIn(this.maxVolume)
        }

        this._playbackHasOccured = true
        this._state = AudioPlayerState.Play
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
    }
    private onPause() {
        if (this.debug) { console.log('<AudioPlayer>::onPause') }
        
        this._state = AudioPlayerState.Pause
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
    }
    // Playback has stopped because the end of the media was reached.
    private onEnded() {
        if (this.debug) { console.log('<AudioPlayer>::onEnded') }
        
        this._state = AudioPlayerState.Pause
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
    }
    private onWaiting() {
        if (this.debug) { console.log('<AudioPlayer>::onWaiting') }
        
        this._state = AudioPlayerState.Loading
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
    }
    
    // Media data loading has been suspended.
    private onSuspend() {
        if (this.debug) { console.log('<AudioPlayer>::onSuspend') }

        // IS THIS TRUE???????????????
        this._state = AudioPlayerState.Pause
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)
    }

    // The user agent is trying to fetch media data, but data is unexpectedly not forthcoming.
    private onStalled() {
        if (this.debug) { console.log('<AudioPlayer>::onStalled') }

        // IS THIS TRUE???????????????
        this._state = AudioPlayerState.Pause
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)

        this.uiResponder.audioPlayerHadAnError('AudioPlayer stalled')
    }

    // private onAbort() {
    //     if (this.debug) { console.log('<AudioPlayer>::onAbort') }
    // }
    // private onCancel() {
    //     if (this.debug) { console.log('<AudioPlayer>::onCancel') }
    // }
    
    // This is a callback on the audio SOURCE
    private onError(event: Event | string) {
        if (this.debug) { console.log('<AudioPlayer>::onError', event) }

        this._state = AudioPlayerState.Error
        this.uiResponder.audioPlayerStateChanged(this._state, this.stateVisual)

        this.uiResponder.audioPlayerHadAnError('AudioPlayer Source loading encountered an error')
    }

    // =======================================================================

    static stringFromPlayerState(s: AudioPlayerState) {
        switch (s) { // If playing, show the PAUSE visual, etc...
            case AudioPlayerState.Play: return 'Play'
            case AudioPlayerState.Pause: return 'Pause'
            case AudioPlayerState.Loading: return 'Loading'
            case AudioPlayerState.Error: return 'Error'
        }
    }

}