















































import firebase from 'firebase/app'
import 'firebase/analytics'

import { Component, Vue } from 'vue-property-decorator'
import Hero from '@/components/Hero.vue'
import StickyHeaderMobile from '@/components/StickyHeaderMobile.vue'
import NavigationDesktop from '@/components/NavigationDesktop.vue'
import EmptyComponent from '@/components/EmptyComponent.vue'
import ProjectExpanded from './components/ProjectExpanded.vue'

import isMobile from "is-mobile"
import FullPage from 'vue-fullpage.js/src/FullPage.vue'
import { ScrollObserver, ScrollObserverDelegate } from './ScrollObserver'
import { ProjectData, projectDataEmpty, projectData } from './data/proj-data'
import StickyHeader from '@/components/StickyHeaderMobile.vue'
import { ScrollLock } from './ScrollLock'

import { tabDataDesktopArray, tabDataMobileArray, TabData } from './data/tab-data'
import { Component as ComponentType } from 'vue'

// import variables from './styles/variables.scss'

// ------------------------------------------------

const isMob = isMobile()
// console.log('isMobile:', isMob)

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQaw6vgtx00We9_nZLilZPHXyu5IedXWg",
  authDomain: "uncanny-valley-site.firebaseapp.com",
  projectId: "uncanny-valley-site",
  storageBucket: "uncanny-valley-site.appspot.com",
  messagingSenderId: "171255519207",
  appId: "1:171255519207:web:969b66703c5dc401dacf42",
  measurementId: "G-6DFMNERL1S"
}

// ------------------------------------------------

@Component({
  components: {
    Hero, 
    StickyHeaderMobile,
    ProjectExpanded,
    NavigationDesktop,
    'full-page' : ((isMob ? EmptyComponent : FullPage) as ComponentType)
  }
})
export default class App extends Vue implements ScrollObserverDelegate {

  firebaseApp: firebase.app.App | null = null

  // -----------------------------------
  // MEMU Stream
  private playerURL = "https://memustream2.live:8443/uv" // PRODUCTION by DEFAULT
  // -----------------------------------

  private isMobileDevice = isMob
  private heroIsVisible = true
  private lockScrollToHero = false

  private selectedTabIndexMobile = 1
  private selectedTabIndexDesktop = 0
  // private get selectedTabIndex(): number {
  //   return this.isMobileDevice ? this.selectedTabIndexMobile : this.selectedTabIndexDesktop
  // }
  private get selectedTabData(): TabData {
    return this.isMobileDevice ? 
    tabDataMobileArray[this.selectedTabIndexMobile] : 
    tabDataDesktopArray[this.selectedTabIndexDesktop]
  }

  private contentMarginTop = '0'
  private tabDataMobile = tabDataMobileArray
  private tabDataDesktop = tabDataDesktopArray

  // -----------------------------
  // Project Expanded
  private projectSelected = false
  private projectViewIsActive = false
  private selectedProjectIndex = 0
  private get selectedProject(): ProjectData {
    return this.projectSelected ? projectData[this.selectedProjectIndex] : projectDataEmpty
  }
  // -----------------------------

  private fullpageOptions = {
    licenseKey: '6A671BFF-83774168-851B32FF-FBBE9A58',
    controlArrows: false,
    keyboardScrolling: false,
    verticalCentered: false,
    responsiveWidth: 992,
    autoScrolling: true,
    fitToSection: true,
    onLeave: this.fullpageOnLeave,
    // afterLoad: this.fullpageAfterLoad,
  }

  stickyHeaderStateChanged(isFixedToTop: boolean, height: number) {
    if (isFixedToTop) {
      this.contentMarginTop = height + 'px'
    } else {
      this.contentMarginTop = '0'
    }
  }

  created() {
    // if (process.env.NODE_ENV == 'development') {
    //   this.playerURL = 'http://139.180.166.143:8000/spin'
    // }

    document.addEventListener('DOMContentLoaded', this.domContentLoaded.bind(this))
    window.onpopstate = this.onPopState
  }

  domContentLoaded() {
    // console.log('app.vue::domContentLoaded')

    const isContentURL = tabDataDesktopArray
      .findIndex((tabDat: TabData) => tabDat.route == document.location.pathname) >= 0

    // The url path is /projects, /about or /team
    if (isContentURL) {
      // console.log('isContentURL', isContentURL)
      this.scrollFullpageDown()
    }

    // =============================
    // INIT ANALYTICS
    if (this.firebaseApp == null) {
      this.firebaseApp = firebase.initializeApp(firebaseConfig)
    }
    if (this.firebaseApp == null) {
      // console.error('Firebase App NOT initialised')
      return
    }

    this.firebaseApp.analytics()

    // this.firebaseFunctions = this.firebaseApp.functions('australia-southeast1')
  }

  onPopState() {
    //ment console.log('document.location', document.location)
    
    const path = document.location.pathname
    const curPathIsContent = tabDataDesktopArray.findIndex((tabDat: TabData) => tabDat.route == document.location.pathname) >= 0

    if (this.isMobileDevice) {
      // MOBILE
      const scrollThresh = StickyHeaderMobile.scrollThreshold
      if (window.scrollY > 0 && path == '/') {
        this.scrollToContentMobile(0) // Scrolls to top
        
      } else if (curPathIsContent && window.scrollY < scrollThresh) {
        this.scrollToContentMobile()
      }
      
    } else if (this.$refs.fullpage) {
      // DESKTOP
      
      // eslint-disable-next-line
      const fp = this.$refs.fullpage as any
      const activeSection = fp.api.getActiveSection()

      if (activeSection.index != 0 && path == '/') {
        fp.api.moveTo(1)
      } else if (curPathIsContent && activeSection.index != 1) {
        fp.api.moveTo(2)
      }
    }
  }

  mounted() {
    new ScrollObserver(this)

    // eslint-disable-next-line
    const fp = this.$refs.fullpage as any
    if (fp.api == undefined) { return }

    // console.log('app.vue mounted', fp.api)
    fp.api.setAllowScrolling(false)
  }

  // ==============================

  interactionAssetsFinishedLoading() {
    // console.log('interactionAssetsFinishedLoading')

    // document.body.classList.add('uv-finished-loading')

    // eslint-disable-next-line
    const fp = this.$refs.fullpage as any
    if (fp.api == undefined) { return }

    fp.api.setAllowScrolling(true)
  }

  interactionModeChangedState(interactionIsActive: boolean) {
    if (!this.isMobileDevice) { return }
    // console.log('app.vue interactionModeChangedState', interactionIsActive)
    this.lockScrollToHero = interactionIsActive
  }

  // ==============================

  tabWasChangedOnStickyHeaderOrDesktopNav(dat: TabData, changeType: string) {
    // console.log('TabData:', dat)
    this.$router.push(dat.route)

    const analytics = this.firebaseApp?.analytics()
    if (!analytics) { return }
    analytics.logEvent('tab_changed', { 
      'tab_title': dat.title, 
      'change_type': changeType 
    })
  }

  stickyHeaderTabChanged(tabIndex: number, type: string) {
    this.selectedTabIndexMobile = tabIndex
    
    if (type != 'user-click') { return }
    this.tabWasChangedOnStickyHeaderOrDesktopNav(
      tabDataMobileArray[tabIndex], type)
  }
  navDesktopLogoClicked() {
    if (this.$refs.fullpage) { 
      // eslint-disable-next-line
      const fp = this.$refs.fullpage as any
      fp.api.moveTo(1)
    }
  }
  navDesktopTabChanged(tabIndex: number, type: string) {
    this.selectedTabIndexDesktop = tabIndex

    if (type != 'user-click') { return }
    this.tabWasChangedOnStickyHeaderOrDesktopNav(
      tabDataDesktopArray[tabIndex], type)
  }

  // ==============================
  // Project Expanded View Events

  projectExpandedViewProjectSelected(index: number) {
    if (this.projectSelected) { return }

    // --------------------------
    const scrollThresh = StickyHeader.scrollThreshold
    if (window.scrollY < scrollThresh) {
      window.scroll({
        top: scrollThresh,
        left: 0, 
        behavior: 'smooth'
      })
    }
    // --------------------------

    this.projectSelected = true
    ScrollLock.setScrollLockActive(this.projectSelected)
    this.selectedProjectIndex = index
    
    setTimeout(() => {
      this.projectViewIsActive = true
    }, 300)
  }

  projectExpandedViewCloseBTNClicked(animDuration: number) {
    // console.log('projectExpandedViewCloseBTNClicked')
    
    this.projectViewIsActive = false

    setTimeout(() => {
      this.projectSelected = false
      ScrollLock.setScrollLockActive(this.projectSelected)
    }, 1000 * animDuration)

    // ANALYTICS EVENT LOG INSIDE ProjectExpanded.vue
  }

  // ==============================
  // ScrollObserverDelegate

  scrollObserverSectionChanged(sectionID: string) {
    // console.log('scrollObserverSectionChanged', sectionID)
    // console.log('this.$router.currentRoute', this.$router.currentRoute)
    const selTabDat = this.selectedTabData

    this.heroIsVisible = (sectionID == 'hero')


    let newPath = '/'
    if (sectionID == 'content-wrapper') { newPath = selTabDat.route }
    // console.log('newPath: ', newPath)

    if (newPath != this.$router.currentRoute.path) {
      if (newPath != '/') {
        this.$router.push(newPath)
      } else {
        window.history.pushState({}, 'home', newPath)
      }
    } else if (document.location.pathname != newPath) {
      window.history.pushState({}, selTabDat.title, newPath)
    }
  }

  // ==============================

  scrollToContentMobile(topScroll?: number) {
    const scrollThresh = topScroll ?? StickyHeaderMobile.scrollThreshold
    if (window.scrollY < scrollThresh) {
      window.scroll({
        top: scrollThresh,
        left: 0, 
        behavior: 'smooth'
      })
    }
  }

  scrollFullpageDown() {

    if (this.isMobileDevice) {
      this.scrollToContentMobile()

    } else {
      // eslint-disable-next-line
      const fp = this.$refs.fullpage as any
      if (fp) { fp.api.moveTo(2) }
    }

    const analytics = this.firebaseApp?.analytics()
    if (!analytics) { return }
    analytics.logEvent('scroll_down_clicked')
  }

  // ------------------------------
  // FULLPAGE CALLBACKS

  // Scroll Begins
  // fullpageOnLeave(origin: FullPageLocation, destination: FullPageLocation, direction: string) {
  fullpageOnLeave(origin: FullPageLocation) {
    // console.log('fullpageOnLeave', origin, destination, direction)
    // console.log('fullpageOnLeave', origin)
    
    if (origin.item.id == 'content-wrapper') {
      this.projectExpandedViewCloseBTNClicked(0.9)
    }
  }

  // Scroll Ends
  // fullpageAfterLoad(origin: App.FullPageLocation, destination: App.FullPageLocation, direction: string) {
    // console.log('fullpageAfterLoad', origin, destination, direction)
  // }

  // ==============================

}

interface FullPageLocation {
    index: number;
    isFirst: boolean;
    isLast: boolean;
    item: HTMLElement;
}

