

























import firebase from 'firebase/app'

import { Component, Prop, Vue } from 'vue-property-decorator'
import MailSVG from '../assets/mail.svg'
import FacebookSVG from '../assets/facebook.svg'
import InstagramSVG from '../assets/instagram.svg'
import TwitterSVG from '../assets/twitter.svg'
import LinkedInSVG from '../assets/linkedin.svg'

@Component({ components: { MailSVG, FacebookSVG, InstagramSVG, TwitterSVG, LinkedInSVG } })
export default class SocialsRow extends Vue {
  @Prop() private selectedTabClass!: string

  contactLinkClicked(type: string) {
    // -----------------------------
    const analytics = firebase.analytics()
    if (!analytics) { return }
    analytics.logEvent('social_btn_clicked', { 'social_type': type })
  }

}
