
import { UVInteractiveEffect } from './UVInteractiveEffect'

export class UVLinesFadeEffect extends UVInteractiveEffect {
    
    setToDefaults() {
        super.setToDefaults()
        
        this.setFadeGradient(6)
        this.setFadeOffset(-10)
    }

    setFadeOffset(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'FadeCenterOffset', val)
    }
    setFadeGradient(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'FadeCenterGradient', val)
    }
}