// import * as BABYLON from 'babylonjs'

import { UVInteractiveEffect, uvColorMap, uvColorArray } from './UVInteractiveEffect'

export class UVColorPulseEffect extends UVInteractiveEffect {

    private isActive = false

    private initSpeedMultiplier = 0.08
    private speedMultiplier = this.initSpeedMultiplier
    // private pulseAccelerationMultiplier = 0.00005

    private _offset = -10
    private get offset(): number { return this._offset }
    private set offset(val: number) { 
        this._offset = val
        this.setColorPulseOffset(val)
    }

    setToDefaults() {
        // console.log('UVColorPulseEffect setToDefaults')
        super.setToDefaults()

        this.offset = 0
        // this.setColorPulseOffset(-10)

        this.setColorPulseGradientOutside(6)
        this.setColorPulseInsideDelta(-10)
        this.setColorPulseGradientInside(12)
    }

    // ----------------------------------------

    emitPulse(index: number) {
        // BACKGROUND COLOR
        const colIndex = index % uvColorArray.length
        const uvCol = uvColorArray[colIndex]
        this.setPulseColor(new BABYLON.Color4(
            uvColorMap[uvCol].r, uvColorMap[uvCol].g, uvColorMap[uvCol].b, 1
        ))

        this.offset = 0
        this.speedMultiplier = this.initSpeedMultiplier

        this.setColorPulseInsideDelta(-50)
        this.setColorPulseGradientInside(500)
        
        this.isActive = true
    }

    setPulseToWhite() {
        this.setPulseColor(new BABYLON.Color4(1,1,1,1))
    }

    // ----------------------------------------

    update(dt: number) {
        // console.log('UVColorPulseEffect.update()')
        this.offset += dt * this.speedMultiplier
        // this.speedMultiplier += dt * this.pulseAccelerationMultiplier
    }

    // ----------------------------------------

    setPulseColor(col: BABYLON.Color4) {
        UVInteractiveEffect.setMatInputColor(this.fxMat, 'PulseColor', col)
    }

    setColorPulseOffset(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'ColorPulseOffset', val)
    }
    setColorPulseGradientOutside(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'ColorPulseGradientOutside', val)
    }

    setColorPulseInsideDelta(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'ColorPulseInsideDelta', val)
    }
    setColorPulseGradientInside(val: number) {
        UVInteractiveEffect.setMatInput(this.fxMat, 'ColorPulseGradientInside', val)
    }
}