
































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import UVLogo from './UVLogo.vue'
import Hero from './Hero.vue'

import { TabData } from '../data/tab-data'
import variables from '../styles/variables.scss'

@Component({
  components: { UVLogo }
})
export default class StickyHeader extends Vue {
  @Prop() private tabDataArray!: Array<TabData>
  private selectedTabIndex = 1 // DEFAULT is the middle tab
  
  static stickyHeaderHeight = 92
  static buttonFontName = variables.uvBodyFont

  static selectorPadding = 3
  private selectorWidth = 84
  private selectorMarginLeft = 35

  private fixToTop = false
  // private topScroll = 'auto'
  private height = StickyHeader.stickyHeaderHeight + 'px'

  private transitionsActive = false

  private heroElement!: HTMLElement
  private get scrollThreshold(): number {
    return this.heroElement.clientHeight - StickyHeader.stickyHeaderHeight
  }
  static get scrollThreshold(): number {
    return (document.getElementById('hero') as HTMLElement).clientHeight - StickyHeader.stickyHeaderHeight
  }

  get selectedTabSpanElement(): HTMLElement {
    const btns = this.$refs.tabBtns as Array<HTMLElement>
    return btns[this.selectedTabIndex].lastChild as HTMLElement
  }

  selectTabWithHTMLElement(el: HTMLElement) {
    // console.log(el.offsetWidth)
    this.selectorMarginLeft = el.offsetLeft - StickyHeader.selectorPadding
    this.selectorWidth = el.offsetWidth + (2 * StickyHeader.selectorPadding)
    // console.log(el.offsetWidth)
  }

  tabClicked(event: Event, index: number) {
    
    // HACKY FIX TO GO TO MEMU
    if (index == 3) {
      window.open("https://memu.live", "_blank");
      return;
    }

    // --------------------------
    window.scroll({
      top: this.scrollThreshold, 
      left: 0, 
      behavior: 'smooth'
    })
    // --------------------------

    if (event.target instanceof HTMLElement) {
      let elementTarget = event.target as HTMLElement
      if (elementTarget.childElementCount > 0) {
        elementTarget = elementTarget.lastChild as HTMLElement
      }
      this.transitionsActive = true
      this.selectTabWithHTMLElement(elementTarget)
    }

    this.selectedTabIndex = index
    this.$emit('tab-changed', this.selectedTabIndex, 'user-click')
  }

  created () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.windowWasResized)
    document.addEventListener('DOMContentLoaded', this.domContentLoaded)
  }
  mounted () {
    // console.log('mounted')
    this.heroElement = document.getElementById('hero') as HTMLElement
    // console.log(this.heroElement)

    this.updateLayout()
  }
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.windowWasResized)
    document.removeEventListener('DOMContentLoaded', this.domContentLoaded)
  }

  // ---------------

  domContentLoaded() {
    this.$nextTick(function () {
      this.selectTabWithHTMLElement(this.selectedTabSpanElement)
      
      setTimeout(() => {
        this.transitionsActive = true
        this.selectTabWithHTMLElement(this.selectedTabSpanElement)
      }, 200)
    })
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(routeDat: { path: string; name: string }) {
    // console.log('Projects::onUrlChange', routeDat)
    const curRoute = routeDat.path
    let index = this.tabDataArray.findIndex(tabDat => tabDat.route === curRoute)
    index = (index < 0) ? 1 : index

    this.selectedTabIndex = index < 0 ? 1 : index
    this.$emit('tab-changed', this.selectedTabIndex, 'route-change')
  }

  // ---------------

  private setHeaderFixedToTop(isFixedToTop: boolean) {
    if (this.fixToTop == isFixedToTop) { return }

    const vueEl = (this.$refs.stickyHeader as HTMLElement)

    if (isFixedToTop) {
      vueEl.style.position = 'fixed'
      vueEl.style.paddingTop = Hero.logoMinHeight + 'px'
    } else {
      vueEl.style.position = 'relative'
      vueEl.style.paddingTop = '0'
    }

    this.fixToTop = isFixedToTop
    this.$emit(
        'state-change', 
        this.fixToTop, 
        StickyHeader.stickyHeaderHeight)
  }

  updateLayout() {
    
    const shouldFixToTop = window.scrollY > this.scrollThreshold
    // if (shouldFixToTop && this.selectedTabIndex != 1) { return }

    this.setHeaderFixedToTop(shouldFixToTop)
  }

  // eslint-disable-next-line
  windowWasResized(ev: Event) {
    this.transitionsActive = false
    this.selectTabWithHTMLElement(this.selectedTabSpanElement)

    this.updateLayout()
  }

  // eslint-disable-next-line
  handleScroll(ev: Event) {
    // console.log('windowScrolled', ev)
    // console.log('window.scrollY', window.scrollY)

    this.updateLayout()
  }

}
