import { UVInteractiveEffect } from './UVInteractiveEffect'

import { WaveData } from '../data/sound-data'
import * as BABYLON from 'babylonjs'

const DEFAULT_BREATHING = { 
    frequency: 5, 
    amplitude: 2, 
    speed: 0.1
}

const TARGET_MOVE_COEFF = 0.006

const TOP_SPEED = 8
//const MIN_PUNCH_SPEED = 12

export class UVBreathingSpeakerCone extends UVInteractiveEffect {
    
    private _lines?: BABYLON.AbstractMesh[]
    setLines(lines: BABYLON.AbstractMesh[]) { this._lines = lines }

    // -------------------------
    effectPercent = 0
    // -------------------------
    private sinPos = 0 // Math.PI * 0.25?
    private _waveData: WaveData = DEFAULT_BREATHING
    private _targetWaveData: WaveData = DEFAULT_BREATHING
    // -------------------------
    

    setToDefaults() {
        // this.sinPos = 0
        // console.log(this._waveData)
        // this._waveData.speed = 0.001
        // this._waveData.amplitude = 2
        // this._waveData.frequency = 5
    }

    performPunch() {
        this.sinPos = Math.PI * -0.04
        
        // INCREASE THE WAVE SPEED
        this._waveData.speed = Math.max(this._targetWaveData.speed, Math.min(TOP_SPEED, this._waveData.speed + 5))
        this._waveData.amplitude = this._targetWaveData.amplitude
        // console.log('speed:', this._waveData.speed)
    }

    setTargetWaveData(dat: WaveData) {
        this._targetWaveData = dat
    }

    // ===============================
    // https://codepen.io/ma77os/pen/KGIEh
    lerp (start: number, end: number, amt: number) { return (1-amt) * start + amt * end }

    updateLerpToTargetWave(dt: number) {
        const movAmount = dt * TARGET_MOVE_COEFF // default = 0.1
        this._waveData.frequency = this.lerp (this._waveData.frequency, this._targetWaveData.frequency, movAmount)
        // this._waveData.amplitude = this.lerp (this._waveData.amplitude, this._targetWaveData.amplitude, movAmount)
        // this._waveData.speed = this.lerp (this._waveData.speed, this._targetWaveData.speed, movAmount)
    }

    update(dt: number) {
        if (!this._lines) {
            console.log('NO LINES')
            return
        }

        this.updateLerpToTargetWave(dt)

        this._waveData.speed = Math.max(1.2, this._waveData.speed - (dt * 0.002))
        this.sinPos += dt * this._waveData.speed * 0.001
        // console.log("posThroughSin: ", this.breathingSinPos)
        // const sinTest = 1 + Math.sin(posThroughSin)
        // console.log("sinTest: ", sinTest)

        const numLines = this._lines.length
        for (let i = 0; i < numLines-1; ++i) {
            const indexPercent = i / numLines
            const localShift = 
                1 + 
                this.effectPercent * this._waveData.amplitude *
                Math.sin(this.sinPos - (indexPercent * this._waveData.frequency))
            const newScale = 1 + (localShift * 0.05)
            
            const line = this._lines[i]
            // const colBrightness = this.fadePercent * 2 - indexPercent
            // line.overlayColor = new BABYLON.Color3(colBrightness, colBrightness, colBrightness)
            line.scaling = new BABYLON.Vector3(newScale, newScale, newScale)
            // line.computeWorldMatrix()
        }
        // Set the scale of the UV Logo
        const scale = this._lines[0].scaling
        this._lines[numLines-1].scaling = new BABYLON.Vector3(scale.x, scale.y, scale.z)
        // this._lines[numLines-1].overlayColor = this._lines[0].overlayColor
        // this._lines[numLines-1].computeWorldMatrix()
    }

} 