




import { Component, Vue } from 'vue-property-decorator'
// import { Component, Prop, Vue } from 'vue-property-decorator'
import UVLogoSVG from '../assets/logo-white.svg'

@Component({ components: { UVLogoSVG } })
export default class UVLogo extends Vue {
  // @Prop() private msg!: string;
}
