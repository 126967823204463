
export class ScrollLock {

    static setScrollLockActive(lockActive: boolean) {
        if (lockActive) {
          this.disableBodyScroll()
        } else {
          this.enableBodyScroll()
        }
    }

    // =============================
    private static preventDefault(e: TouchEvent){
        e.preventDefault();
    }
    private static disableBodyScroll() {
        document.body.addEventListener('touchmove', ScrollLock.preventDefault, { passive: false });
    }
    private static enableBodyScroll() {
        document.body.removeEventListener('touchmove', ScrollLock.preventDefault);
    }
    // =============================
}