import { UVInteractiveEffect, uvColorMap, uvColorArray, UVColor } from './UVInteractiveEffect'

const uvColorMaxPercent: { [col: string]: number } = {
    'white' : 0.5,
    'red' : 0.6,
    'yellow' : 0.6,
    'blue-dark' : 1.2,
    'blue-light' : 0.6
}

export class UVBackgroundFlashEffect extends UVInteractiveEffect {
    
    // -------------------------
    private bgColorIsActive = false
    private bgColorPercent = 0
    private bgColor = BABYLON.Color3.White()

    private maxPercent = 0.6

    setToDefaults() {
        this.bgColorIsActive = false
        this.bgColorPercent = 0
        this.bgColor = BABYLON.Color3.White()
    }

    performFlash(index: number) {
        // BACKGROUND COLOR
        const colIndex = index % uvColorArray.length
        const uvCol = uvColorArray[colIndex]
        this.bgColor = uvColorMap[uvCol]

        this.maxPercent = uvColorMaxPercent[uvCol]

        this.bgColorPercent = 0
        this.bgColorIsActive = true
    }

    disableFlash() {
        this.bgColorIsActive = false
        this.bgColorPercent = 0
        this.bgColor = uvColorMap[UVColor.White]
    }

    update(dt: number): BABYLON.Color4 {
        if (!this.bgColorIsActive && this.bgColorPercent <= 0) { return new BABYLON.Color4(0,0,0,1) }
        this.bgColorPercent += 0.05 * (this.bgColorIsActive ? dt : (-0.008 * dt))
        
        if (this.bgColorIsActive) {
            if (this.bgColorPercent > this.maxPercent) {
                this.bgColorPercent = this.maxPercent
                this.bgColorIsActive = false
            }
        } else {
            if (this.bgColorPercent < 0) { this.bgColorPercent = 0 }
        }

        const inversePercent = 1 - this.bgColorPercent
        return new BABYLON.Color4(
            this.bgColorPercent * this.bgColor.r,
            this.bgColorPercent * this.bgColor.g,
            this.bgColorPercent * this.bgColor.b,
            1
        )
    }

} 