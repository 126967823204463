





















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import UVLogo from './UVLogo.vue'
import UVTextLogoSVG from '../assets/logo-text-white.svg'

import SocialsRow from './SocialsRow.vue'

import { TabData } from '../data/tab-data'

import variables from '../styles/variables.scss'

@Component({
  components: { UVLogo, UVTextLogoSVG, SocialsRow }
})
export default class NavigationDesktop extends Vue {
  @Prop() private tabDataArray!: Array<TabData>

  private selectedTabIndex = 0 // DEFAULT is the TOP tab
  private get socialsSelectedTabClass(): string {
    return this.tabDataArray[this.selectedTabIndex].title.toLowerCase()
  }
  
  static stickyHeaderHeight = variables.mobileHeaderHeightSection
  static buttonFontName = variables.uvBodyFont

  static selectorPadding = 3
  private selectorWidth = 84
  private selectorMarginLeft = 35

  private transitionsActive = false

  get selectedTabSpanElement(): HTMLElement {
    const btns = this.$refs.tabBtns as Array<HTMLElement>
    return btns[this.selectedTabIndex].lastChild as HTMLElement
  }

  // eslint-disable-next-line
  selectTabWithHTMLElement(el: HTMLElement) {
    // console.log(el.offsetWidth)
    // this.selectorMarginLeft = el.offsetLeft - StickyHeader.selectorPadding
    // this.selectorWidth = el.offsetWidth + (2 * StickyHeader.selectorPadding)
    // console.log(el.offsetWidth)
  }

  logoClicked() {
    this.$emit('logo-clicked')
  }

  tabClicked(event: Event, index: number) {

    // HACKY FIX TO GO TO MEMU
    if (index == 3) {
      window.open("https://memu.live", "_blank");
      return;
    }

    if (event.target instanceof HTMLElement) {
      let elementTarget = event.target as HTMLElement
      if (elementTarget.childElementCount > 0) {
        elementTarget = elementTarget.lastChild as HTMLElement
      }
      this.transitionsActive = true
      this.selectTabWithHTMLElement(elementTarget)
    }

    this.$emit('tab-changed', index, 'user-click')
    this.selectedTabIndex = index
  }

  // created () {
  //   window.addEventListener('scroll', this.handleScroll)
  //   window.addEventListener('resize', this.windowWasResized)
  //   document.addEventListener('DOMContentLoaded', this.domContentLoaded)
  // }

  // destroyed () {
  //   window.removeEventListener('scroll', this.handleScroll)
  //   window.removeEventListener('resize', this.windowWasResized)
  //   document.removeEventListener('DOMContentLoaded', this.domContentLoaded)
  // }

  // ---------------

  domContentLoaded() {
    this.$nextTick(function () {
      this.selectTabWithHTMLElement(this.selectedTabSpanElement)
      
      setTimeout(() => {
        this.transitionsActive = true
        this.selectTabWithHTMLElement(this.selectedTabSpanElement)
      }, 200)
    })
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(routeDat: { path: string; name: string }) {
    // console.log('Projects::onUrlChange', routeDat)
    const curRoute = routeDat.path
    let index = this.tabDataArray.findIndex(tabDat => tabDat.route === curRoute)
    index = (index < 0) ? 0 : index

    const newIndex = index < 0 ? 0 : index
    this.$emit('tab-changed', newIndex, 'route-change')
    this.selectedTabIndex = newIndex
  }

  // ---------------

  windowWasResized() {
    this.transitionsActive = false
    this.selectTabWithHTMLElement(this.selectedTabSpanElement)
  }

  // eslint-disable-next-line
  // handleScroll(ev: Event) {
    // console.log('windowScrolled', ev)
    // console.log('window.scrollY', window.scrollY)
  // }

}
