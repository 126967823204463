






import { Component, Prop, Vue } from 'vue-property-decorator'
// import { Component, Prop, Vue } from 'vue-property-decorator'
// import UVLogoSVG from '../assets/logo-white.svg'

@Component
export default class EmptyComponent extends Vue {
  // eslint-disable-next-line
  @Prop() private options!: Record<string, any>
  // @Prop() private msg!: string;
}
