// https://academind.com/tutorials/scroll-aware-navigation/

export interface ScrollObserverDelegate {
    scrollObserverSectionChanged(sectionID: string): void;
}

export class ScrollObserver {

    delegate: ScrollObserverDelegate

    observerOptions = {
        root: null,
        rootMargin: '0px', //400px 0px 0px 0px',
        threshold: 0.5,
    }

    sections: HTMLDivElement[] = []
    // navBar : HTMLDivElement
    // navTitle : HTMLHeadingElement
    // navDesktopTitle : HTMLHeadingElement

    private sectionIDs = ['hero', 'content-wrapper']
    private currentSectionID = this.sectionIDs[0]
    private isIntersectingMap = new Map<string, boolean>()

    // Constructor should be called inside DOMContentFinishedLoading or after
    constructor(delegate: ScrollObserverDelegate) {
        this.delegate = delegate

        // this.navBar = document.getElementById('nav-bar') as HTMLDivElement
        // this.navTitle = document.getElementById('nav-title') as HTMLHeadingElement
        // this.navDesktopTitle = document.getElementById('nav-title-desktop') as HTMLHeadingElement

        for (let i = 0; i < this.sectionIDs.length; i++) {
            const sectionID = this.sectionIDs[i];
            
            this.sections.push(document.getElementById(sectionID) as HTMLDivElement)
            // this.sectionMap.set(sectionID, this.sectionTitles[i])
            this.isIntersectingMap.set(sectionID, i == 0) // The first section can start as true
        }

        this.setup()
    }

    private scrollCallback(entries: IntersectionObserverEntry[]) {
        // console.log("ScrollCallback entries.length: " + entries.length)

        entries.forEach((entry) => {
            this.isIntersectingMap.set(entry.target.id, entry.isIntersecting)
        })

        // const numIntersecting: number = Array.from( this.isIntersectingMap.values() )
        //     .reduce((acc: number, isInter: boolean) => { return acc + (isInter ? 1:0) }, 0)
        // console.log("numIntersecting : " + numIntersecting)

        for (const key of Array.from( this.isIntersectingMap.keys()) ) {
            const isIntersecting = this.isIntersectingMap.get(key)
            if (isIntersecting) {
                if (key != this.currentSectionID) {
                    this.currentSectionID = key
                    this.delegate?.scrollObserverSectionChanged(key)
                }
                break
            }
        }
    }

    private setup() {
        const observer = new IntersectionObserver(this.scrollCallback.bind(this), this.observerOptions)

        this.sections.forEach((sec) => observer.observe(sec))
    }

}