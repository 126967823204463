
// import { Engine, Scene, AssetsManager, ArcRotateCamera } from 'babylonjs';
import * as BABYLON from 'babylonjs'
import { OBJFileLoader } from 'babylonjs-loaders'

import { Howl } from 'howler'

// import { UVColor, uvColorMap, uvColorArray } from './UVInteractiveEffect'debug
import { soundData, UVSound, UVEffectType, UVButtonPosition, getButtonPositionKey } from '../data/sound-data'
// import { UVButtonPosition } from '../data/sound-data'

import { UVInteractiveLoadingScreen, UVInteractiveLoadingScreenDelegate } from './UVInteractiveLoadingScreen'

import { UVBreathingSpeakerCone } from './UVBreathingSpeakerCone'
import { UVLinesFadeEffect } from './UVLinesFadeEffect'
import { UVBackgroundFlashEffect } from './UVBackgroundFlashEffect'
import { UVColorPulseEffect } from './UVColorPulseEffect'
import { UVInnerWaveEffect } from './UVInnerWaveEffect'

export interface UVInteractiveDelegate extends UVInteractiveLoadingScreenDelegate {
    interactionActiveStateChanged(isActive: boolean): void;
    getIsMobile(): boolean;
    interactiveViewIsVisible(): boolean;
    interactionAssetsFinishedLoading(): void;
    interactionSoundWasTriggered(): void;
}

BABYLON.SceneLoaderFlags.ShowLoadingScreen = false
OBJFileLoader.SKIP_MATERIALS = true
OBJFileLoader.COMPUTE_NORMALS = false
OBJFileLoader.IMPORT_VERTEX_COLORS = false

export class UVInteractiveController implements UVInteractiveLoadingScreenDelegate {

    delegate: UVInteractiveDelegate
    canvas: HTMLCanvasElement
    engine: BABYLON.Engine
    scene: BABYLON.Scene
    private get camera(): BABYLON.Camera { return this.scene.cameras[0] }
    private loadingScreen: UVInteractiveLoadingScreen

    private _loadingScreenVisible = true
    public get loadingScreenVisible() { return this._loadingScreenVisible }

    private isMobile: boolean
    private soundsLoaded = false
    private assetsLoaded = false

    private throttledResize = false
    private lastWinWidth = 0
    private lastWinHeight = 0
    private timeAfterLastWinCheck = 0
    private lastInputTime = 0
    private lastInputID = ""

    // ====================================================
    private mobileButtonSoundIndexMap: { [name: string]: number } = {}
    private keycodeSoundIndexMap: { [pos: string]: number } = {}

    // private streamShouldBePlaying = false
    // private memuStream: Howl | null = null

    private static soundURLPrefix = "https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/sounds-uv%2F"
    private sounds: { [name: string]: Howl } = {}

    // ====================================================

    private logoMaterial: BABYLON.NodeMaterial
    private lines: BABYLON.AbstractMesh[] = []
    private linesMaterial: BABYLON.NodeMaterial
    
    private effectBreathingSpeaker: UVBreathingSpeakerCone
    private effectBGFlash: UVBackgroundFlashEffect
    private effectColorPulse: UVColorPulseEffect
    private effectLinesFade: UVLinesFadeEffect
    private effectInnerWave: UVInnerWaveEffect

    private _updateLogoVisibility = false
    private _isLogoVisible = true
    get isLogoVisible() { return this._isLogoVisible }
    set isLogoVisible(newVal: boolean) {
        if (newVal == this._isLogoVisible) { return }
        this._isLogoVisible = newVal
        this._updateLogoVisibility = true
        // console.log('this._isLogoVisible', this._isLogoVisible)
        // console.log('this._updateLogoVisibility', this._updateLogoVisibility)
    }

    private _isActive = false
    get isActive() { return this._isActive }
    set isActive(newVal: boolean) {
        if (newVal == this._isActive) { return }

        this._isActive = newVal
        this.delegate.interactionActiveStateChanged(this._isActive)
    }
    private fadePercent = 0

    // ====================================================

    allAssetsLoaded() {
        // console.log('allAssetsLoaded')

        this.loadingScreen.hideLoadingScreen()
        this.delegate?.interactionAssetsFinishedLoading()

        // this.createMemuStream()
        // if (this.memuStream == null) { 
        //     console.log('memuStream == null')
        //     return
        // }
        // console.log(this.memuStream)
        // this.memuStream.load()
    }

    constructor(delegate: UVInteractiveDelegate, canvas: HTMLCanvasElement, isMobile: boolean) {
        this.delegate = delegate
        this.canvas = canvas
        this.isMobile = isMobile

        const engineOpts: BABYLON.EngineOptions = { doNotHandleTouchAction: false, autoEnableWebVR: false }
        this.engine = new BABYLON.Engine(canvas, true, engineOpts) // Generate the BABYLON 3D engine
        this.loadingScreen = new UVInteractiveLoadingScreen(this)
        this.engine.loadingScreen = this.loadingScreen
        // this.engine.displayLoadingUI()
        this.scene = UVInteractiveController.createScene(this.engine)

        this.linesMaterial = new BABYLON.NodeMaterial("uv_lines_mat", this.scene, { emitComments: false })
        this.logoMaterial = new BABYLON.NodeMaterial("uv_logo_mat", this.scene, { emitComments: false })

        this.effectBreathingSpeaker = new UVBreathingSpeakerCone(this.linesMaterial, this.logoMaterial)
        this.effectLinesFade = new UVLinesFadeEffect(this.linesMaterial, this.logoMaterial)
        this.effectBGFlash = new UVBackgroundFlashEffect(this.linesMaterial, this.logoMaterial)
        this.effectColorPulse = new UVColorPulseEffect(this.linesMaterial, this.logoMaterial)
        this.effectInnerWave = new UVInnerWaveEffect(this.linesMaterial, this.logoMaterial)

        this.updateCameraDistance()

        this.loadSounds((soundRequestsFinished: number, totalSounds: number) => {
            // COMPLETE CALLBACK
            // console.log('soundsLoaded', soundRequestsFinished, 'totalSounds', totalSounds)

            if (totalSounds == soundRequestsFinished) {
                this.soundsLoaded = true
                if (this.assetsLoaded) { this.allAssetsLoaded() }
            }
        })
        this.loadAssets(() => {
            this.assetsLoaded = true
            if (this.soundsLoaded) { this.allAssetsLoaded() }

            this.effectInnerWave.setToDefaults()
            this.effectLinesFade.setToDefaults()

            // COMPLETE CALLBACK
            this.scene.registerBeforeRender(this.updateBeforeRender.bind(this))
            this.engine.runRenderLoop(() => this.scene.render())

            // console.log('UVInteractiveController Asset Loading complete!')
        })

        // this.scene.debugLayer.show({ embedMode: true })

        // console.log('renderCanvas: ', canvas)

        // Watch for browser/canvas resize events
        window.addEventListener("resize", this.windowResized.bind(this))
        if (!isMobile) {
            window.onkeydown = this.keyDownCallback.bind(this)
        }
        // document.addEventListener('keydown', this.keyDownCallback.bind(this))
        // window.addEventListener('focus', this.windowFocusOccurred)
        // window.addEventListener('blur', this.windowBlurOccurred)
        document.addEventListener("DOMContentLoaded", this.domContentLoaded.bind(this))
        window.addEventListener('load', this.pageLoaded.bind(this))
        
        // const fsEvents = ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange"]
        // fsEvents.forEach(e => document.addEventListener(e, this.fullscreenChanged.bind(this)), false)

        // console.log(OBJ)
    }

    static createScene(engine: BABYLON.Engine): BABYLON.Scene {
        const scene = new BABYLON.Scene(engine)

        scene.clearColor = new BABYLON.Color4(0,0,0,1)
        scene.useRightHandedSystem = true

        const camera = new BABYLON.ArcRotateCamera("camera", Math.PI * 1.5, 0, 100, BABYLON.Vector3.Zero(), scene);
        camera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;

        return scene
    }

    // createMemuStream() {
    //     console.log('createMemuStream')
    //     this.memuStream = new Howl({
    //         src: ['https://memustream.live:8443/uv_site_live_20'],
    //         format: ['mpeg'],
    //         // format: ['mp3', 'aac'],
    //         preload: false,
    //         html5: true,
    //         onload: () => {
    //             console.log('Memu stream loaded...')
    //             // this.memuStream.play()
    //         },
    //         onloaderror: (soundID, loaderr) => { console.log(loaderr) },
    //         onplay: (soundID) => {
    //             console.log('on play')
    //             if (!this.delegate.interactiveViewIsVisible()) { 
    //                 this.memuStream!.volume(0) // HERO NOT VISIBLE
    //             } else {
    //                 this.memuStream!.fade(0.0, 1.0, 400)
    //             }
    //         },
    //         onplayerror: (soundID, playerr) => { console.log(playerr) }
    //     })
    // }

    domContentLoaded() {
        this.updateCameraDistance() // DO WE NEED THIS?
    }
    pageLoaded() {
        this.updateCameraDistance() // DO WE NEED THIS?
        
    }

    // interactiveViewVisibilityChanged(isVisible: boolean) {
    //     console.log('interactiveViewVisibilityChanged')
    //     if (this.memuStream == null) { return }

    //     if (this.memuStream.volume() <= 0 && !isVisible ||
    //         this.memuStream.volume() >= 1 &&  isVisible) { return }

    //     this.memuStream.fade(
    //         this.memuStream.volume(),   // FROM volume
    //         isVisible ? 1 : 0,          // TO volume
    //         isVisible ? 900 : 1500)     // DURATION
    // }

    // ====================================================

    //     #     #####   #####  ####### #######    #       #######    #    ######  ### #     #  #####  
    //    # #   #     # #     # #          #       #       #     #   # #   #     #  #  ##    # #     # 
    //   #   #  #       #       #          #       #       #     #  #   #  #     #  #  # #   # #       
    //  #     #  #####   #####  #####      #       #       #     # #     # #     #  #  #  #  # #  #### 
    //  #######       #       # #          #       #       #     # ####### #     #  #  #   # # #     # 
    //  #     # #     # #     # #          #       #       #     # #     # #     #  #  #    ## #     # 
    //  #     #  #####   #####  #######    #       ####### ####### #     # ######  ### #     #  #####  
               
    loadSounds(progressCallback: (soundRequestsFinished: number, totalSounds: number) => void) {
        let numLoaded = 0
        soundData
        .forEach((sDatum: UVSound, index: number) => {

            // Create the keycode map
            sDatum.keycodes.forEach((keyCode: string) => this.keycodeSoundIndexMap[keyCode] = index)
            this.mobileButtonSoundIndexMap[getButtonPositionKey(sDatum.mobileButtonPos)] = index

            // --------------------------------
            const soundBase = UVInteractiveController.soundURLPrefix + sDatum.name
            const s = new Howl({
                src: [soundBase+ ".webm?alt=media", soundBase+ ".mp3?alt=media", soundBase+ ".ogg?alt=media"],
                format: ['webm', 'mp3', 'ogg'],
                preload: true,
                onload: () => {
                    // console.log('Sound loaded', soundId)
                    ++numLoaded
                    progressCallback(numLoaded, soundData.length)
                },
                onloaderror: (soundId: number, error: unknown) => {
                    console.error('Sound load error', soundId, error)
                    ++numLoaded
                    progressCallback(numLoaded, soundData.length)
                }
            })
            this.sounds[sDatum.name] = s
        })

        // console.log(this.sounds)
        // console.log(this.keycodeSoundIndexMap)
        // console.log(this.mobileButtonSoundIndexMap)
    }

    // -----------------------------

    loadAssets(complete: () => void) {
        const assetsManager = new BABYLON.AssetsManager(this.scene)
        assetsManager.addMeshTask("all_uv_shape_task", "", "/", "uv_logo_0-47.obj")

        assetsManager.onFinish = (tasks: BABYLON.AbstractAssetTask[]) => {
            this.assetsFinishedLoading(tasks)
            complete()
        }


        this.logoMaterial.loadAsync("./uvlogo-mat.json")
        .then(() => {
            // build node material 
            this.logoMaterial.build(false)
        })
        .then(() => this.linesMaterial.loadAsync("./uvlines-mat.json"))
        .then(() => {
            // build node material 
            this.linesMaterial.build(false)
            assetsManager.load()
        })
    }

    assetsFinishedLoading(tasks: BABYLON.AbstractAssetTask[]) {
        tasks.forEach((task) => {
            if ((task as BABYLON.MeshAssetTask).loadedMeshes !== undefined) {
                const meshes: BABYLON.AbstractMesh[] = (task as BABYLON.MeshAssetTask).loadedMeshes
                for (let i = 0; i < meshes.length - 1; ++i) {
                    const m = meshes[i]
                    // m.renderOverlay = true
                    // m.overlayAlpha = 1
                    m.material = this.linesMaterial
                    // const mID = m.id;
                    this.lines.push(m)
                    // console.log(mID);
                }
                const uvLogoMesh = meshes[meshes.length-1]
                uvLogoMesh.material = this.logoMaterial
                if (this.isMobile) {
                    uvLogoMesh.overlayAlpha = 1
                    uvLogoMesh.overlayColor = BABYLON.Color3.Black()
                    uvLogoMesh.renderOverlay = true
                    this._isLogoVisible = false
                }
                this.lines.push(uvLogoMesh)

                this.effectBreathingSpeaker.setLines(this.lines)
            }
        })
    }
    
    // ====================================================

    //  #       #######    #    ######  ### #     #  #####      #####   #####  ######  ####### ####### #     # 
    //  #       #     #   # #   #     #  #  ##    # #     #    #     # #     # #     # #       #       ##    # 
    //  #       #     #  #   #  #     #  #  # #   # #          #       #       #     # #       #       # #   # 
    //  #       #     # #     # #     #  #  #  #  # #  ####     #####  #       ######  #####   #####   #  #  # 
    //  #       #     # ####### #     #  #  #   # # #     #          # #       #   #   #       #       #   # # 
    //  #       #     # #     # #     #  #  #    ## #     #    #     # #     # #    #  #       #       #    ## 
    //  ####### ####### #     # ######  ### #     #  #####      #####   #####  #     # ####### ####### #     # 
                                                                                                        

    loadingScreenWasHidden() {
        this._loadingScreenVisible = false
        this.delegate.loadingScreenWasHidden()
    }

    // ====================================================

    //  #     # ######  ######     #    ####### ####### 
    //  #     # #     # #     #   # #      #    #       
    //  #     # #     # #     #  #   #     #    #       
    //  #     # ######  #     # #     #    #    #####   
    //  #     # #       #     # #######    #    #       
    //  #     # #       #     # #     #    #    #       
    //   #####  #       ######  #     #    #    ####### 
                                                 
    updateBeforeRender() {
        if (this.engine == null) { return }
        const dt = this.engine.getDeltaTime() // ~16 (16/1000)=60fps

        this.updateWindowResizeFix(dt)

        if (this.isActive && this.fadePercent < 1.0) {
            this.fadePercent = this.fadePercent + Math.min(1.0, dt * 0.0003)
            this.effectBreathingSpeaker.effectPercent = this.fadePercent
            this.effectLinesFade.setFadeOffset(-5 + this.fadePercent * 100)
        } else if (!this.isActive && this.fadePercent > 0) {
            this.fadePercent = this.fadePercent - Math.max(0, dt * 0.001)
            this.effectBreathingSpeaker.effectPercent = this.fadePercent
            this.effectLinesFade.setFadeOffset(-5 + this.fadePercent * 100)
        }

        
        if (this._updateLogoVisibility) {
            const uvLogoMesh = this.lines[this.lines.length-1]
            if (!this._isLogoVisible && uvLogoMesh.overlayAlpha < 1) {
                // FADE OUT
                uvLogoMesh.overlayAlpha = uvLogoMesh.overlayAlpha + (dt * 0.0015)
                if (uvLogoMesh.overlayAlpha > 1) {
                    uvLogoMesh.overlayAlpha = 1
                    this._updateLogoVisibility = false
                }
            } else if (this._isLogoVisible && uvLogoMesh.overlayAlpha > 0) {
                // FADE IN
                uvLogoMesh.overlayAlpha = uvLogoMesh.overlayAlpha - (dt * 0.0004)
                if (uvLogoMesh.overlayAlpha < 0) {
                    uvLogoMesh.overlayAlpha = 0
                    this._updateLogoVisibility = false
                }
            }
        }

        // VISUAL EFFECTS
        // =======

        // BREATHING CONE EFFECT
        this.effectBreathingSpeaker.update(dt)

        // BG COLOR CHANGE
        this.scene.clearColor = this.effectBGFlash.update(dt)

        // COLOR PULSE
        this.effectColorPulse.update(dt)

        // =======
    }

    updateWindowResizeFix(dt: number) {
        this.timeAfterLastWinCheck += dt
        if (this.timeAfterLastWinCheck > 400) {
            const rect = this.engine.getRenderingCanvasClientRect()
            if (rect != null && (rect.width != this.lastWinWidth || rect.height != this.lastWinHeight)) {
                // console.log('RESIZE FIX')
                this.engine.resize()
                this.updateCameraDistance(rect)
            }
            this.timeAfterLastWinCheck = 0
        }
    }

    // ----------------------------------------------------

    // windowFocusOccurred() {
    //     // console.log('windowFocusOccurred')
    //     // this.scene.registerBeforeRender(updateLoop);
    // }
    // windowBlurOccurred() {
    //     // console.log('windowBlurOccurred')
    //     // this.scene.unregisterBeforeRender(updateLoop);
    // }                                             

    // ====================================================
    
    //         ### #     # ######  #     # ####### 
    //          #  ##    # #     # #     #    #    
    //          #  # #   # #     # #     #    #    
    //          #  #  #  # ######  #     #    #    
    //          #  #   # # #       #     #    #    
    //          #  #    ## #       #     #    #    
    //         ### #     # #        #####     #    

    triggerSoundWithPosition(pos: UVButtonPosition) {
        // console.log('triggerSoundWithPosition', pos)

        const posKey = getButtonPositionKey(pos)
        if (!(posKey in this.mobileButtonSoundIndexMap)) { return }
        if (!this.isActive) { this.isActive = true }

        const soundIndex = this.mobileButtonSoundIndexMap[posKey]
        if (soundIndex >= soundData.length) { return }

        this.triggerSound(soundData[soundIndex])
    }

    keyDownCallback(evt: KeyboardEvent) {
        // console.log(this.delegate.interactiveViewIsVisible())
        const c = evt.code
        // console.log(c)
        if (c == 'Tab') { evt.preventDefault() }

        if (evt.metaKey) { return }
        if (this.loadingScreenVisible || !this.delegate.interactiveViewIsVisible()) { return }
        if (!this.isMobile && window.innerWidth < 992) { return }
        
        // DEBOUNCE
        const curTime = +new Date()

        if (c == this.lastInputID && 
            this.lastInputTime > 0 && curTime - this.lastInputTime < 60) { return }
        
        // const k = evt.key
        // console.log('KeyboardEvent.code: ', c)
        // console.log('KeyboardEvent.key: ', k)

        // --------------------------------
        if (!(c in this.keycodeSoundIndexMap)) { return }
        if (!this.isActive) { this.isActive = true }
        // evt.preventDefault() // STOP BROWSER ACTIONS, like spacebar causing scroll

        const soundIndex = this.keycodeSoundIndexMap[c]
        if (soundIndex >= soundData.length) { return }
        this.triggerSound(soundData[soundIndex])

        this.lastInputTime = curTime
        this.lastInputID = c
        // return false
    }

    // ====================================================
    //   #####  ####### #     # #     # ######  
    //  #     # #     # #     # ##    # #     # 
    //  #       #     # #     # # #   # #     # 
    //   #####  #     # #     # #  #  # #     # 
    //        # #     # #     # #   # # #     # 
    //  #     # #     # #     # #    ## #     # 
    //   #####  #######  #####  #     # ######  
                                 
    triggerSound(soundDatum: UVSound) {
        const soundHowl = this.sounds[soundDatum.name]
        soundHowl.play()

        // Change the shader properties
        if (soundDatum.innerWaveDataExists) {
            this.effectInnerWave.setWaveData(soundDatum.innerWaveData)
        }
        this.effectBreathingSpeaker.setTargetWaveData(soundDatum.breathingConeWaveData)
        // if (soundDatum.breathingConeDataExists) {
        //     this.effectBreathingSpeaker.setTargetWaveData(soundDatum.breathingConeWaveData)
        // }
        
        if (soundDatum.visualEffect == UVEffectType.BackgroundColorFlash) {
            this.effectBGFlash.performFlash(soundDatum.colorIndex)
            this.effectColorPulse.setPulseToWhite()
        } else if (soundDatum.visualEffect == UVEffectType.ColorPulse) {
            this.effectColorPulse.emitPulse(soundDatum.colorIndex)
            this.effectBGFlash.disableFlash()
        } else if (soundDatum.visualEffect == UVEffectType.None) {
            this.effectBGFlash.disableFlash()
            // this.effectColorPulse.setPulseToWhite()
        }

        this.effectBreathingSpeaker.performPunch()

        this.delegate.interactionSoundWasTriggered()
        // if (!this.streamShouldBePlaying && this.memuStream != null) {
        //     this.memuStream.play()
        //     this.streamShouldBePlaying = true
        // }
    }

    // ====================================================

    //         #####  ######  ####  # ###### ###### 
    //         #    # #      #      #     #  #      
    //         #    # #####   ####  #    #   #####  
    //         #####  #           # #   #    #      
    //         #   #  #      #    # #  #     #      
    //         #    # ######  ####  # ###### ###### 
                        
    updateCameraDistance(engineRect?: ClientRect | null) {
        const rect = engineRect ?? this.engine.getRenderingCanvasClientRect()
        if (rect == null || this.scene.cameras.length < 1) { return }
        const aspectIsPortrait = rect.height > rect.width

        // The more SQUARE it is, the less it should respect the edge
        // It should zoom more, the more square it is.
        const ratio = rect.height / rect.width
        const squareCoefficient = Math.max(0, (0.5 - Math.abs(1 - ratio)) * 2)
        // console.log('squareCoefficient', squareCoefficient)
        
        let camDist
        let reduction
        if (aspectIsPortrait) {
            // PORTRAIT
            camDist = 80 / ratio
            reduction = squareCoefficient * 20

        } else {
            // LANDSCAPE
            camDist = 80
            reduction = squareCoefficient * 20
        }
        camDist -= reduction
        // console.log('reduction', reduction)
        // console.log('camDist', camDist)

        const cam = this.camera
        cam.orthoLeft = -camDist / 2
        cam.orthoRight = camDist / 2
        
        cam.orthoTop = cam.orthoRight * ratio
        cam.orthoBottom = cam.orthoLeft * ratio

        this.lastWinWidth = rect.width
        this.lastWinHeight = rect.height
    }

    // TODO: Debounce and delay the window resize?
    windowResized() {
        // only run if we're not throttled
        if (!this.throttledResize) {
            this.engine.resize()
            this.updateCameraDistance()

            this.throttledResize = true // THROTTLED 
            // UNTIL...
            setTimeout(() => {
                this.updateCameraDistance()
                this.throttledResize = false
            }, 350)
        }
    }

    // fullscreenChanged(evt: Event) {
    //     // console.log('fullscreenChanged', evt)
    // }

    // ====================================================
    
    
}