export interface TeamData {
    name: string;
    jobTitle: string;
    imgURL: string;
    linkedInURL: string;
    imgLoaded: boolean;
}

export const teamDataArray: Array<TeamData> = [
    {
        name: 'Justin Shave', 
        jobTitle: 'Head Composer, Sonic Technologist & Co-Founder', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fjustin-shave-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/justin-shave-9735431b',
        imgLoaded: false
    },{
        name: 'Charlton Hill', 
        jobTitle: 'Head of Music & Innovation & Co-founder', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fcharlton-hill-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/charltonhill',
        imgLoaded: false
    },{
        name: 'Ariane Sallis', 
        jobTitle: 'Creative Producer', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fariane-sallis-uv-03.jpg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/ariane-sallis-7516a8100',
        imgLoaded: false
        // -------------------------------------------------
    },{
        name: 'Caroline Pegram', 
        jobTitle: 'Strategy and Networks Advisor', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fcaroline-pegram-uv.jpg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/caroline-pegram-94046416',
        imgLoaded: false
    },{
        name: 'David Barber', 
        jobTitle: 'Composer/Orchestrator', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fdave-barber-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/davidbarbermusic',
        imgLoaded: false
    },{
        name: 'Tyler Burrows', 
        jobTitle: 'Composer/Programmer', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Ftyler-burrows-uv.jpeg?alt=media',
        linkedInURL: 'https://www.theloop.com.au/Tyler_Burrows',
        imgLoaded: false
        // -------------------------------------------------
    },{
        name: 'Caleb Jacobs', 
        jobTitle: 'Composer', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fcaleb-jacobs-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/caleb-jacobs-949706119',
        imgLoaded: false
    },{
        name: 'Felix Wallis', 
        jobTitle: 'Composer', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Ffelix-wallis-uv.jpg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/felixwallis',
        imgLoaded: false
    },{
        name: 'Cassie Parke', 
        jobTitle: 'Composer/Orchestrator', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fcassie-parke-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/cassie-parke',
        imgLoaded: false
        // -------------------------------------------------
    },{
        name: 'Dr. Brendan Wright', 
        jobTitle: 'Research Data Scientist,<br>Machine Learning Engineer', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fbrendan-wright-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/brendan-f-wright',
        imgLoaded: false
    },{
        name: 'Oliver Bown',
        jobTitle: 'Academic Partner: Associate Professor UNSW', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Foliver-bown-uv.jpg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/oliver-bown-8ba52715/?originalSubdomain=au',
        imgLoaded: false
    },{
        name: 'Matt Perrott', 
        jobTitle: 'Sound Design & Broadcast mix', 
        imgURL: 'https://firebasestorage.googleapis.com/v0/b/uncanny-valley-site.appspot.com/o/team%2Fmatt-perrot-uv.jpeg?alt=media',
        linkedInURL: 'https://www.linkedin.com/in/matt-perrott-98924922',
        imgLoaded: false
    }
]