



















import { Component, Vue } from 'vue-property-decorator';
import { TeamData, teamDataArray } from '../data/team-data'

// import variables from '../styles/variables.scss'

@Component
export default class Team extends Vue {
  private teamData: Array<TeamData> = teamDataArray

  created () {
    this.teamData.forEach(td => td.imgLoaded = false)
  }

  imageLoaded(index: number) {
    // console.log('TEAM::image loaded', index)
    setTimeout(() => {
      this.teamData[index].imgLoaded = true
    }, 200)
  }

}
