















import { Component, Prop, Vue } from 'vue-property-decorator'
import StickyHeader from '../components/StickyHeaderMobile.vue'
import Hero from './Hero.vue'

import { ProjectData } from '../data/proj-data'

@Component
export default class ProjectView extends Vue {
  @Prop() private index!: number
  @Prop() private datum!: ProjectData
  private isMobile!: boolean
  private imageIsLoaded = false

  private objPosYPercent = 50
  static maxParrallaxRange = 75
  static maxParrallaxRangePadding = (100 - ProjectView.maxParrallaxRange) * 0.5

  static headerHeight = +StickyHeader.stickyHeaderHeight + Hero.logoMinHeight

  private boundScrollFunc: ((evt: Event) => void) | null = null

  private get getImgSrc(): string {
    return this.datum.imgSrcSmall ?? ''
    // return this.isMobile ? this.datum.imgSrcSmall! : this.datum.imgSrc!
  }

  created () {
    this.boundScrollFunc = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.boundScrollFunc)
  }

  mounted() {
    this.calculateImageObjectPosition()
  }

  destroyed () {
    if (this.boundScrollFunc == null) { return }
    window.removeEventListener('scroll', this.boundScrollFunc)
  }

  imageLoaded() {
    // console.log('image loaded', ev)
    setTimeout(() => {
      this.imageIsLoaded = true
    }, 200)
  }

  calculateImageObjectPosition() {
    const el = this.$refs.project as HTMLAnchorElement
    const bounds = el.getBoundingClientRect()

    const visibleHeight = window.innerHeight - ProjectView.headerHeight
    const percent = (bounds.y - ProjectView.headerHeight) / visibleHeight

    this.objPosYPercent = percent * ProjectView.maxParrallaxRange + ProjectView.maxParrallaxRangePadding
  }

  // eslint-disable-next-line
  handleScroll(_ev: Event) {
    // console.log("scroll", this.index, this.datum.imgSrc)
    this.calculateImageObjectPosition()

    // if (this.index > 0) { return }
    // console.log('window.innerHeight: ', window.innerHeight)
    // console.log('ProjectView.headerHeight: ', ProjectView.headerHeight)
    // console.log('StickyHeader.stickyHeaderHeight: ', StickyHeader.stickyHeaderHeight)
    // console.log('Hero.logoMinHeight: ', Hero.logoMinHeight)
    
    // console.log(percent, bounds.y, visibleHeight)
  }

  projectClicked() {
    // console.log("projectClicked", datum)
    this.$emit('project-clicked', this.index)
  }

}
