export interface TabData {
    route: string;
    url?: string;
    title: string;
    colorHex: string;
    description: string;
    descriptionAlt?: string;
}

export const tabDataEmpty: TabData = {
    route: '',
    url: undefined,
    title: '',
    colorHex: '#000000',
    description: ''
}

const aboutCopy = 'At Uncanny Valley we place a musical lens on technology. Founded in 2010, by music producer/<wbr>computer scientist Justin Shave and songwriter/<wbr>entrepreneur Charlton Hill,<br>Uncanny Valley’s focus is one part musical craft, and the other sonic technology. We believe in the pursuit of augmented creativity and strive to define the ethical and sustainable role of emerging tech in the ever-changing landscape of the music industry. We consult and speak regularly at global conferences on the future of technology in the creative arts.'
const addressCopy = '<b>UV Studios:</b> 200 Crown St, Darlinghurst. NSW. 2092<br>M: +61 400 439 355'

export const tabDataDesktopArray: Array<TabData> = [
    {
        route: '/projects', 
        title: 'Projects', 
        colorHex: '#DFDC36',
        description: 'Uncanny Valley is where music, artists and technology collide.<br>\
        Armed with decades of experience, we harness our diverse musical expertise and collaborate with artists and brands to create their unique sound. We utilise emerging technologies and engage audiences to tell musical stories via all mediums. Our extensive portfolio includes strategic sonic branding for campaign launches, artist engagements and releases, music technology prototyping, series format themes and score, channel branding and network promos.'
    },{
        route: '/about', 
        title: 'About', 
        colorHex: '#AADDE2',
        description: '<p class="about-content">' + aboutCopy + '<br><br>' + addressCopy + '</p>',
        descriptionAlt: '<p class="about-content mobile-only">'+ aboutCopy + '</p>' +'\
        <p class="about-services mobile-only"><br class="mobile-only"><b class="mobile-only">Services</b><br class="mobile-only">Sonic branding<br>Original music composition<br>Songwriting<br>Licence Track Re-records<br>Remixes and supervision<br>Sonic Technology R&D<br>Prototyping<br>Musical Direction<br>Sound Design<br>Voice Over direction<br>Broadcast Mixing<br class="mobile-only"><br class="mobile-only"><p class="mobile-only">' + addressCopy + '</p>'
    },{
        route: '/team',
        title: 'Team', 
        colorHex: '#FF4E00',
        description: 'As composers, songwriters and music producers we specialise in creating music to enhance or redefine the emotion of any experience. As sonic technologists, innovators and strategists we have been at the forefront of using artificial intelligence and machine learning as collaborative tools for the music industry. We partner with academics and technologists to pursue augmented creative intelligence and progressive musical solutions in a collaborative environment.'
    },{
        route: '',
        url: 'https://memu.live/',
        title: 'MEMU', 
        colorHex: '#8F51DF',
        description: ''
    }
]

export const tabDataMobileArray: Array<TabData> = [ 
    tabDataDesktopArray[1], tabDataDesktopArray[0], tabDataDesktopArray[2], tabDataDesktopArray[3]
]