
export enum UVEffectType {
    None = 0,
    BackgroundColorFlash = 1,
    ColorPulse = 2
}

export interface WaveData {
    frequency: number;
    amplitude: number;
    speed: number;
}
const WAVE_DATA_DEFAULTS = { frequency: 7, amplitude: 0.5, speed: 0.2 }

export interface UVButtonPosition { 
    x: number; 
    y: number; 
}

function pad(num: number, size: number) { return ('000000000' + num).substr(-size) }
export function getButtonPositionKey(position: UVButtonPosition): string {
    return "[" + pad(position.x, 2) + "," + pad(position.y, 2) + "]"
}

// -------------------------------

export class UVSound {
    name: string;
    keycodes: string[];
    mobileButtonPos: UVButtonPosition

    visualEffect: UVEffectType;
    colorIndex = 0;

    private _breathingConeWaveData?: WaveData;
    private _innerWaveData?: WaveData;
    get breathingConeDataExists(): boolean { return this._breathingConeWaveData != undefined }
    get innerWaveDataExists(): boolean { return this._innerWaveData != undefined }

    get breathingConeWaveData(): WaveData { return this._breathingConeWaveData ?? WAVE_DATA_DEFAULTS }
    get innerWaveData(): WaveData { return this._innerWaveData ?? WAVE_DATA_DEFAULTS }

    constructor(dat: { 
        name: string;
        keycodes: string[];
        mobileButtonPos: UVButtonPosition;
    }, optionalDat: {
        visualEffect?: UVEffectType;
        colorIndex?: number;
        innerWave?: WaveData;
        breathingWave?: WaveData;
    } = { visualEffect: UVEffectType.None, colorIndex: 0 }) {
        this.name = dat.name
        this.keycodes = dat.keycodes
        this.mobileButtonPos = dat.mobileButtonPos
        this.visualEffect = optionalDat.visualEffect ?? UVEffectType.None
        this.colorIndex = optionalDat.colorIndex ?? 0

        if (optionalDat.innerWave) { this._innerWaveData = optionalDat.innerWave }
        if (optionalDat.breathingWave) { this._breathingConeWaveData = optionalDat.breathingWave }
    }
}

// TODO: Generate a MAP from... 
//         - Keycodes to array index
//         - MobileButtonPositions to array index

// Keycodes:
//    Key[A-Z] (ie. 'KeyA')
//    Digit
//    'Backquote'

/*
    UVSOUND_MONO_01 -> (C,5) DONE
    UVSOUND_MONO_02 -> (6)   DONE
    UV_SOUND_01 -> (Period)  DONE
    UV_SOUND_02 -> (D)       DONE
    UV_SOUND_03 -> (Space)   DONE
    UV_SOUND_04              DONE
    UV_SOUND_05              DONE
    UV_SOUND_06 -> (1,F)     DONE
    UV_SOUND_07 -> (R,=)     DONE
    UV_SOUND_08 -> (3,B)     DONE
    UV_SOUND_09              DONE
    UV_SOUND_10 -> (Q,8)     DONE
    UV_SOUND_11              DONE
    UV_SOUND_12 -> (BracketLeft,U) DONE
    UV_SOUND_13              DONE
    UV_SOUND_14 -> (Comma,f7) DONE
    UV_SOUND_15 -> (2,W)      DONE
    UV_SOUND_16 -> (M,X)      DONE
    UV_SOUND_17              DONE
    UV_SOUND_18              DONE
    UV_SOUND_19              DONE
    UV_SOUND_20 -> (G)        DONE
    UV_SOUND_21              DONE
    UV_SOUND_22              DONE
    UV_SOUND_23 -> (J)        DONE
    UV_SOUND_24              DONE
    UV_SOUND_25 -> (A)        DONE
    UV_SOUND_26              DONE
    UV_SOUND_27              DONE
    UV_SOUND_28              DONE
    UV_SOUND_29              DONE
    UV_SOUND_30              DONE
    UV_SOUND_31              DONE
    UV_SOUND_32 -> (K)       DONE
    UV_SOUND_33              DONE
    UV_SOUND_34              DONE
*/

export const soundData: Array<UVSound> = [
    new UVSound({
        name: "UV_SOUND_06",
        keycodes: ['Digit1', 'KeyF', 'F12'],
        mobileButtonPos: { x: 0, y: 0 }
    },{
        breathingWave: { frequency: 16, amplitude: 2, speed: 12 },
        innerWave: { frequency: 16, amplitude: 0.1, speed: -1.4 }
    }), 
    
    new UVSound({
        name: "UV_SOUND_04",
        keycodes: ['Digit2', 'KeyN', 'F11'],
        mobileButtonPos: {x: 1, y: 0},
    }, {
        breathingWave: { frequency: 1, amplitude: 5, speed: 10 },
        innerWave: { frequency: 5, amplitude: 0.4, speed: 0.8 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 0
     }),

    new UVSound({
        name: "UV_SOUND_08",
        keycodes: ['Digit3', 'KeyB', 'F10'],
        mobileButtonPos: {x: 2, y: 0}
    }, {
        breathingWave: { frequency: 10, amplitude: 2, speed: 12 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 3
    }), 

    new UVSound({
        name: "UV_SOUND_05",
        keycodes: ['Digit4', 'KeyV', 'F9'],
        mobileButtonPos: {x: 3, y: 0}

    }, {
        breathingWave: { frequency: 13, amplitude: 4.5, speed: 8 },
        innerWave: { frequency: 3, amplitude: 0.6, speed: -0.8 }
    }), 
    new UVSound({ // ======================================
        name: "UVSOUND_MONO_01",
        keycodes: ['Digit5', 'KeyC'],
        mobileButtonPos: {x: 0, y: 1}
    }, {
        breathingWave: { frequency: 14, amplitude: 5, speed: 1 },
        innerWave: { frequency: 2, amplitude: 0.64, speed: 0.4 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 1
    }), 
    
    new UVSound({
        name: "UVSOUND_MONO_02",
        keycodes: ['Digit6'],
        mobileButtonPos: {x: 1, y: 1}
    }, {
        breathingWave: { frequency: 20, amplitude: 2.5, speed: 12 },
        innerWave: { frequency: 1, amplitude: 0.7, speed: -1 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 2
    }), 
    
    new UVSound({
        name: "UV_SOUND_16",
        keycodes: ['Digit7', 'KeyM'],
        mobileButtonPos: {x: 2, y: 1}
    }, {
        breathingWave: { frequency: 13, amplitude: 2.5, speed: 8 },
        innerWave: { frequency: 4, amplitude: 0.7, speed: 0.6 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 0
    }),
    
    new UVSound({
        name: "UV_SOUND_10",
        keycodes: ['Digit8', 'KeyQ'],
        mobileButtonPos: {x: 3, y: 1}
    }, {
        breathingWave: { frequency: 10, amplitude: 2, speed: 12 },
        innerWave: { frequency: 14, amplitude: 0.1, speed: 1 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 2 
    }), 
    
    new UVSound({ // ======================================
        name: "UV_SOUND_15",
        keycodes: ['Digit9', 'KeyW'],
        mobileButtonPos: {x: 0, y: 2}
    }, {
        breathingWave: { frequency: 7, amplitude: 3.5, speed: 6 },
        innerWave: { frequency: 5, amplitude: 0.6, speed: 0.8 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 1 
    }), 
    
    new UVSound({
        name: "UV_SOUND_09",
        keycodes: ['KeyE', 'Digit0'],
        mobileButtonPos: {x: 1, y: 2}
    }, {
        breathingWave: { frequency: 16, amplitude: 1, speed: 12 },
        innerWave: { frequency: 11, amplitude: 0.2, speed: 0.8 },
    }),
    
    new UVSound({
        name: "UV_SOUND_07",
        keycodes: ['KeyR'],
        mobileButtonPos: {x: 2, y: 2}
    }, {
        breathingWave: { frequency: 13, amplitude: 2.5, speed: 8 },
        innerWave: { frequency: 6, amplitude: 0.4, speed: -0.4 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 3 
    }), 
    
    new UVSound({
        name: "UV_SOUND_11",
        keycodes: ['KeyT'],
        mobileButtonPos: {x: 3, y: 2}
    }, {
        breathingWave: { frequency: 20, amplitude: 0.5, speed: 12 },
        innerWave: { frequency: 15, amplitude: 0.05, speed: -1 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 0 
    }), 
    
    new UVSound({ // ======================================
        name: "UV_SOUND_13",
        keycodes: ['KeyY'],
        mobileButtonPos: {x: 0, y: 3}
    }, {
        breathingWave: { frequency: 11, amplitude: 3.5, speed: 6 },
        innerWave: { frequency: 3, amplitude: 0.64, speed: 0.2 },
    }), 
    
    new UVSound({
        name: "UV_SOUND_12",
        keycodes: ['KeyU', 'Quote'],
        mobileButtonPos: {x: 1, y: 3}
    }, {
        breathingWave: { frequency: 10, amplitude: 2, speed: 12 },
        innerWave: { frequency: 7, amplitude: 0.3, speed: 1 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 2 
    }), 
    
    new UVSound({
        name: "UV_SOUND_17",
        keycodes: ['Backquote', 'KeyI'],
        mobileButtonPos: {x: 2, y: 3}
    }, {
        breathingWave: { frequency: 13, amplitude: 2.5, speed: 8 },
        innerWave: { frequency: 8, amplitude: 0.2, speed: 0.2 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 1 
    }), 
    
    new UVSound({
        name: "UV_SOUND_18",
        keycodes: ['Slash', 'KeyO'],
        mobileButtonPos: {x: 3, y: 3}
    }, {
        breathingWave: { frequency: 20, amplitude: 0.5, speed: 12 },
        innerWave: { frequency: 12, amplitude: 0.1, speed: -0.4 },
    }),
    
    new UVSound({ // ======================================
        name: "UV_SOUND_19",
        keycodes: ['KeyP'],
        mobileButtonPos: {x: 0, y: 4}
    }, {
        breathingWave: { frequency: 16, amplitude: 3.5, speed: 6 },
        innerWave: { frequency: 5, amplitude: 0.7, speed: 0.6 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 1 
    }), 
    
    new UVSound({
        name: "UV_SOUND_21",
        keycodes: ['KeyL'],
        mobileButtonPos: {x: 1, y: 4}
    }, {
        breathingWave: { frequency: 16, amplitude: 1, speed: 12 },
        innerWave: { frequency: 9, amplitude: 0.3, speed: -0.8 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 2 
    }), 
    
    new UVSound({
        name: "UV_SOUND_32",
        keycodes: ['ArrowLeft', 'KeyK'],
        mobileButtonPos: {x: 2, y: 4}
    }, {
        breathingWave: { frequency: 14, amplitude: 3.5, speed: 8 },
        innerWave: { frequency: 3, amplitude: 0.6, speed: 0.2 },
    }), 
    
    new UVSound({
        name: "UV_SOUND_23",
        keycodes: ['ArrowRight', 'KeyJ'],
        mobileButtonPos: {x: 3, y: 4}
    }, {
        breathingWave: { frequency: 20, amplitude: 3, speed: 6 },
        innerWave: { frequency: 2, amplitude: 0.64, speed: -0.2 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 0 
    }), 
    
    new UVSound({ // ======================================
        name: "UV_SOUND_22",
        keycodes: ['F1', 'KeyH'],
        mobileButtonPos: {x: 0, y: 5}
    }, {
        breathingWave: { frequency: 10, amplitude: 2, speed: 12 },
        innerWave: { frequency: 5, amplitude: 0.3, speed: -0.8 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 3 
    }), 
    
    new UVSound({
        name: "UV_SOUND_20",
        keycodes: ['F2', 'KeyG'],
        mobileButtonPos: {x: 1, y: 5}
    }, {
        breathingWave: { frequency: 16, amplitude: 5, speed: 2 },
        innerWave: { frequency: 1, amplitude: 0.7, speed: -1 },
    }), 
    
    new UVSound({
        name: "UV_SOUND_24",
        keycodes: ['F3', 'KeyZ'],
        mobileButtonPos: {x: 2, y: 5}
    }, {
        breathingWave: { frequency: 16, amplitude: 1, speed: 12 },
        innerWave: { frequency: 13, amplitude: 0.2, speed: 0.5 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 1 
    }), 
    
    new UVSound({
        name: "UV_SOUND_02",
        keycodes: ['F4', 'KeyD'],
        mobileButtonPos: {x: 3, y: 5}
    }, {
        breathingWave: { frequency: 15, amplitude: 5, speed: 2 },
        innerWave: { frequency: 3, amplitude: 0.7, speed: 0.2 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 2 
    }), 
    
    new UVSound({ // ======================================
        name: "UV_SOUND_26",
        keycodes: ['F5', 'KeyS'],
        mobileButtonPos: {x: 0, y: 6}
    }, {
        breathingWave: { frequency: 16, amplitude: 1, speed: 12 },
        innerWave: { frequency: 16, amplitude: 0.1, speed: 0.8 },
    }), 
    
    new UVSound({
        name: "UV_SOUND_25",
        keycodes: ['F6', 'KeyA'],
        mobileButtonPos: {x: 1, y: 6}
    }, {
        breathingWave: { frequency: 20, amplitude: 0.5, speed: 12 },
        innerWave: { frequency: 15, amplitude: 0.17, speed: -1 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 2 
    }), 
    
    new UVSound({
        name: "UV_SOUND_14",
        keycodes: ['F7', 'Comma'],
        mobileButtonPos: {x: 2, y: 6}
    }, {
        breathingWave: { frequency: 16, amplitude: 1, speed: 12 },
        innerWave: { frequency: 7, amplitude: 0.4, speed: -0.4 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 1 
    }), 
    
    new UVSound({
        name: "UV_SOUND_01",
        keycodes: ['F8', 'Period'],
        mobileButtonPos: {x: 3, y: 6}
    }, {
        breathingWave: { frequency: 7, amplitude: 3.5, speed: 6 },
        innerWave: { frequency: 4, amplitude: 0.64, speed: 0.6 },
    })
    , new UVSound({
        name: "UV_SOUND_03",
        keycodes: ['Space'],
        mobileButtonPos: {x: 0, y: 7}
    }, {
        breathingWave: { frequency: 7, amplitude: 3.5, speed: 6 },
        innerWave: { frequency: 4, amplitude: 0.64, speed: 0.6 },
    }),

    new UVSound({
        name: "UV_SOUND_27",
        keycodes: ['KeyX'],
        mobileButtonPos: {x: 1, y: 7}
    }, {
        breathingWave: { frequency: 19, amplitude: 2, speed: 8 },
        innerWave: { frequency: 5, amplitude: 0.4, speed: 0.6 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 0
    }),





    new UVSound({
        name: "UV_SOUND_28",
        keycodes: ['Minus'],
        mobileButtonPos: {x: 2, y: 7}
    }, {
        breathingWave: { frequency: 12, amplitude: 2, speed: 12 },
        innerWave: { frequency: 10, amplitude: 0.2, speed: 0.8 },
    }),
    
    new UVSound({
        name: "UV_SOUND_29",
        keycodes: ['Equal'],
        mobileButtonPos: {x: 3, y: 7}
    }, {
        breathingWave: { frequency: 7, amplitude: 2.5, speed: 8 },
        innerWave: { frequency: 7, amplitude: 0.4, speed: -0.4 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 3 
    }), 
    
    new UVSound({
        name: "UV_SOUND_30",
        keycodes: ['Backslash'],
        mobileButtonPos: {x: -1, y: -1}
    }, {
        breathingWave: { frequency: 16, amplitude: 1.5, speed: 12 },
        innerWave: { frequency: 12, amplitude: 0.05, speed: -1 },
        visualEffect: UVEffectType.BackgroundColorFlash, colorIndex: 0 
    }), 
    
    new UVSound({ // ======================================
        name: "UV_SOUND_31",
        keycodes: ['BracketLeft'],
        mobileButtonPos: {x: -1, y: -1}
    }, {
        breathingWave: { frequency: 5, amplitude: 4.5, speed: 6 },
        innerWave: { frequency: 5, amplitude: 0.64, speed: 0.2 },
    }), 
    
    new UVSound({
        name: "UV_SOUND_33",
        keycodes: ['BracketRight'],
        mobileButtonPos: {x: -1, y: -1}
    }, {
        breathingWave: { frequency: 7, amplitude: 3, speed: 12 },
        innerWave: { frequency: 3, amplitude: 0.3, speed: 1 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 2 
    }),
    new UVSound({ // ======================================
        name: "UV_SOUND_34",
        keycodes: ['Semicolon'],
        mobileButtonPos: {x: -1, y: -1}
    }, {
        breathingWave: { frequency: 5, amplitude: 2.5, speed: 6 },
        innerWave: { frequency: 2, amplitude: 0.7, speed: 0.6 },
        visualEffect: UVEffectType.ColorPulse, colorIndex: 1 
    }),  
]